import React, { useState, useRef, useEffect } from "react";
import { Box, Flex, Button, Text, Menu, MenuButton, MenuList, MenuItem, Image, Radio, RadioGroup, Stack,   } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { Stage, Layer, Line, Image as KonvaImage, Text as KonvaText } from "react-konva";
import SolveHeader from "./SolveHeader";
import ToolBar from "./ToolBar";
import QuestionAnswerBox from "./QuestionAnswerBox";
import FeedBack from "./FeedBack";
import TextCursorIcon from "../Images/Textbox-Cursor-Icon.svg";
import AnnotateCursorIcon from "../Images/Pen-Cursor-Icon.svg";
import EraserCursorIcon from "../Images/Eraser-Cursor-Icon.svg";
import MenuArrow from "../Images/Menu_Arrow.svg";
import { throttle } from "lodash";
import TextBox from "./TextBox";
import SubmitIcon from "../Images/Submit-Icon.svg";
import SubmitIconDark from "../Images/Submit-Icon-Dark.svg";
import html2canvas from "html2canvas";
import { BASE_API_URL } from './config';

const AnnotateSubmitMenu = ({ questionParts = [], handleSnapshot, questionGuid, onSelectPart}) => {
  const [selectedPart, setSelectedPart] = useState(null);

  const handleSelectPart = (part) => {
    console.log("Selected part:", part);
    setSelectedPart(part);
    onSelectPart(part.guid);  // Notify parent component of selected part
  };

  return (
    <Menu >
      <MenuButton
        as={Button}
        variant = "menuButton"
        width="132px"
        height="28px"
        textAlign="center"
        fontSize="14px"
        fontWeight="400"
        background="white"
        border="2.333px solid #E7EAE8"
        borderRadius="9.333px"
        rightIcon={<img src={MenuArrow} alt="menu arrow" />}
      >
        {selectedPart ? selectedPart.label : "Upload to"}
      </MenuButton>

      <MenuList background="white" color="#021A04" textAlign="center" fontSize="12px" fontWeight="300">
        {Array.isArray(questionParts) && questionParts.length > 0 ? (
          questionParts.map((part, index) => (
            <MenuItem key={index} onClick={() => handleSelectPart(part)}>
              {part.label}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>No Question Parts Available</MenuItem>
        )}
      </MenuList>

      {selectedPart && (
        <Button
          id={`submit-${questionGuid}`}  // Unique ID for the button
          onClick={() => {
            handleSnapshot(questionGuid, selectedPart.guid);
          }}
          marginLeft="8px"
          width="132px"
          height="28px"
          textAlign="center"
          fontSize="14px"
          fontWeight="400"
          background="var(--Main-font-color, #021A04)"
          color="var(--Primary, #F1F1F1)"
          borderRadius="9.333px"
          _hover={{
            background: "var(--Primary, #F1F1F1)",
            color: "var(--Main-font-color, #021A04)",
          }}
        >
          Upload Annotation
        </Button>
      )}
    </Menu>
  );
};

const SolvePaper = () => {
  const location = useLocation();
  const { questions = [], paper_Guid, subject_name, formattedPaperName, time_hour, time_min } = location.state || {};
  const [tool, setTool] = useState(null);
  const [annotations, setAnnotations] = useState({});
  const [lines, setLines] = useState({});
  const [answers, setAnswers] = useState({});
  const [feedbackData, setFeedbackData] = useState({});
  const [loadingFeedback, setLoadingFeedback] = useState({});
  const [isAnnotating, setIsAnnotating] = useState({});
  const [images, setImages] = useState({});
  const [loadingImages, setLoadingImages] = useState({});
  const [history, setHistory] = useState([]);
  const [redoStack, setRedoStack] = useState([]);
  const [selectedPartGuid, setSelectedPartGuid] = useState(null);
  const [selectedPartSnapshot, setSelectedPartSnapshot] = useState(null);
  const [strokeWidth, setStrokeWidth] = useState(1);
  const [testStarted, setTestStarted] = useState(false);  
  const [selectedOption, setSelectedOption] = useState({}); 
  const [submitIcon, setSubmitIcon] = useState(SubmitIcon);
  const [submittedOptions, setSubmittedOptions] = useState({}); 
  const [timeUp, setTimeUp] = useState(false);
  const isDrawing = useRef(false);
  const stageRefs = useRef({});
  const token = localStorage.getItem("accessToken");

  useEffect(() => {
    if (selectedPartGuid) {
      const questionGuid = Object.keys(answers).find((qGuid) => answers[qGuid]?.[selectedPartGuid]);
      if (questionGuid) {
        const snapshot = answers[questionGuid]?.[selectedPartGuid]?.dataURL;
        if (snapshot) {
          setSelectedPartSnapshot(snapshot);
        }
      }
    }
  }, [selectedPartGuid, answers]);

  const handleStrokeWidthChange = (width) => {
    setStrokeWidth(width);
  };

  const handleSelectPart = (guid) => {
    setSelectedPartGuid(guid); // Set the selected part GUID
  };

  useEffect(() => {
    if (questions.length > 0) {
      questions.forEach((question) => {
        // Load existing feedback and mark if available
        if (question.user_question_attempt) {
          setFeedbackData((prevData) => ({
            ...prevData,
            [question.guid]: {
              mark: question.user_question_attempt.feedback_mark,
              feedback: question.user_question_attempt.feedback_text,
              feedbackStatus: question.user_question_attempt.feedback_correctness,
              sampleImage: question.user_question_attempt.sample_image || "no image url",
              userType: question.user_question_attempt.permission_access
            },
          }));
        }
      });
    }
  }, [questions]);

  useEffect(() => {
    const resizeHandler = () => {
      Object.keys(stageRefs.current).forEach((key) => {
        const stage = stageRefs.current[key];
        const img = images[key]; // Access the image using the same key as the stage
    
        if (stage && img) {
          const dimensions = getKonvaImageDimensions(img);
          stage.width(dimensions.width);
          stage.height(dimensions.height);
    
          // Redraw the stage to apply the new dimensions
          stage.draw();
        }
      });
    };
    
    window.addEventListener("resize", resizeHandler);
    resizeHandler();
    return () => window.removeEventListener("resize", resizeHandler);
  }, []);

  const loadImages = (question) => {
    const imgKeys = ['image_1', 'image_2', 'image_3', 'image_4', 'image_5'];
    const loadedImages = {};

    imgKeys.forEach((imgKey) => {
      if (question[imgKey]) {
        const img = new window.Image();
        img.crossOrigin = "anonymous";
        img.src = question[imgKey];
        img.onload = () => {
          setImages((prevImages) => ({
            ...prevImages,
            [`${question.guid}_${imgKey}`]: img,
          }));
        };
        img.onerror = (err) => console.error(`Error loading image: ${question[imgKey]}`, err);
      }
    });
  };

  useEffect(() => {
    if (questions.length > 0) {
      questions.forEach(loadImages);
    }
  }, [questions]);

  const getKonvaImageDimensions = (image) => {
    if (!image || !image.width || !image.height) {
      return { width: 500, height: 300 }; // Fallback values if the image is not loaded
    }
  
    const aspectRatio = image.width / image.height;
    const maxWidth = window.innerWidth * 0.6; // Adjust width to a percentage of the window width
    const maxHeight = window.innerHeight * 0.6; // Adjust height to a percentage of the window height
  
    if (aspectRatio > 1) {
      // Landscape orientation
      const width = Math.min(image.width, maxWidth);
      return {
        width,
        height: width / aspectRatio,
      };
    } else {
      // Portrait or square orientation
      const height = Math.min(image.height, maxHeight);
      return {
        width: height * aspectRatio,
        height,
      };
    }
  };

  useEffect(() => {
    const handleUndoRedo = (e) => {
      if ((e.ctrlKey || e.metaKey) && e.key === "z") {
        undo();
      } else if ((e.ctrlKey || e.metaKey) && e.key === "y") {
        redo();
      }
    };
    window.addEventListener("keydown", handleUndoRedo);
    return () => window.removeEventListener("keydown", handleUndoRedo);
  }, [history, redoStack]);

  
  const handleMouseDown = (e, questionGuid) => {
    if (!e.target.getStage) return;
    const stage = e.target.getStage();
    if (tool === "pen") {
      isDrawing.current = true;
      const pos = stage.getPointerPosition();
      setLines((prevLines) => ({
        ...prevLines,
        [questionGuid]: [
          ...(prevLines[questionGuid] || []),
          { tool, points: [pos.x, pos.y], strokeWidth }, // Include strokeWidth here
        ],
      }));
      setIsAnnotating((prevState) => ({
        ...prevState,
        [questionGuid]: true,
      }));
    }
  };
  
  const throttledMouseMove = throttle((e, questionGuid) => {
    if (!isDrawing.current || !e.target.getStage) return;
    const stage = e.target.getStage();
    const point = stage.getPointerPosition();
    setLines((prevLines) => {
      const currentLines = prevLines[questionGuid] || [];
      const lastLine = currentLines[currentLines.length - 1];
      if (!lastLine) return prevLines;
      lastLine.points = lastLine.points.concat([point.x, point.y]);
      return {
        ...prevLines,
        [questionGuid]: [...currentLines.slice(0, -1), lastLine],
      };
    });
  }, 10);

  const handleMouseMove = (e, questionGuid) => {
    throttledMouseMove(e, questionGuid);
  };

  const handleMouseUp = (questionGuid) => {
    isDrawing.current = false;
    saveHistory(questionGuid);
  };

  const saveHistory = (questionGuid) => {
    setHistory((prevHistory) => [
      ...prevHistory,
      {
        questionGuid,
        lines: [...(lines[questionGuid] || [])],
      },
    ]);
    setRedoStack([]);
  };

  const handleCanvasClick = (e, questionGuid) => {
    if (tool !== "text") return;
  
    const stage = e.target.getStage();
    const { x, y } = stage.getPointerPosition();
  
    setAnnotations((prevAnnotations) => ({
      ...prevAnnotations,
      [questionGuid]: [
        ...(prevAnnotations[questionGuid] || []),
        { x, y, content: "", id: `${Date.now()}` },
      ],
    }));
  
    // Set the annotation mode so the menu will show up for the added text box
    setIsAnnotating((prevState) => ({
      ...prevState,
      [questionGuid]: true,
    }));
  };
  
  

  const handleErase = (e, questionGuid) => {
    if (tool !== "eraser") return;
    const stage = e.target.getStage();
    const point = stage.getPointerPosition();
  
    setLines((prevLines) => ({
      ...prevLines,
      [questionGuid]: prevLines[questionGuid].filter((line) => {
        if (line.tool !== "pen") return true; // Skip non-pen lines to keep them unaffected
  
        return !line.points.some((coord, index) => 
          index % 2 === 0 && Math.abs(coord - point.x) < 10 && Math.abs(line.points[index + 1] - point.y) < 10
        );
      }),
    }));
  };
  
  const undo = () => {
    if (history.length === 0) return;
    const lastAction = history.pop();
    const { questionGuid, lines: previousLines } = lastAction;
    setLines((prevLines) => ({
      ...prevLines,
      [questionGuid]: previousLines,
    }));
    setRedoStack((prevRedo) => [...prevRedo, lastAction]);
    setHistory([...history]);
  };

  const redo = () => {
    if (redoStack.length === 0) return;
    const lastUndone = redoStack.pop();
    const { questionGuid, lines: previousLines } = lastUndone;
    setLines((prevLines) => ({
      ...prevLines,
      [questionGuid]: previousLines,
    }));
    setHistory((prevHistory) => [...prevHistory, lastUndone]);
    setRedoStack([...redoStack]);
  };

  const handleContentChange = (content, id, questionGuid) => {
    setAnnotations((prevAnnotations) => ({
      ...prevAnnotations,
      [questionGuid]: prevAnnotations[questionGuid].map((ann) =>
        ann.id === id ? { ...ann, content } : ann
      ),
    }));
  };
  
  const handleDelete = (id, questionGuid) => {
    setAnnotations((prevAnnotations) => ({
      ...prevAnnotations,
      [questionGuid]: prevAnnotations[questionGuid].filter((ann) => ann.id !== id),
    }));
  };
  
  
  const updateAnnotationPosition = (position, annotationId, questionGuid) => {
    const { x, y } = position; // Position contains updated x and y
    setAnnotations((prevAnnotations) => ({
      ...prevAnnotations,
      [questionGuid]: prevAnnotations[questionGuid].map((annotation) =>
        annotation.id === annotationId ? { ...annotation, x, y } : annotation
      ),
    }));
  };
  
  useEffect(() => {
    // Cleanup function to reset the cursor on unmount
    return () => {
      document.body.style.cursor = "auto";
      setTool(null); // Reset tool state for safety
    };
  }, []);


  const handleAddText = () => {
    setTool(tool === "text" ? null : "text");
  };
  
  const handleAnnotate = () => {
    setTool(tool === "pen" ? null : "pen");
  };
  
  const handleEraseTool = () => {
    setTool(tool === "eraser" ? null : "eraser");
  };
  
  const handleResetCursor = () => {
    setTool(null); // Reset active tool state
  };

  
  const processQuestionTextWithImages = (
    text,
    question,
    questionGuid,
    stageRefs,
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
    tool,
    questionLines,
    questionAnnotations,
    isAnnotating,
    handleSnapshot,
    questionParts
  ) => {
    const elements = [];
    let remainingText = text || "";
  
    const imagePlaceholderRegex = /\[IMAGE_(\d+)\]/g;
    let match;
    let lastIndex = 0;
  
    while ((match = imagePlaceholderRegex.exec(remainingText)) !== null) {
      const placeholderIndex = parseInt(match[1], 10);
      const imageKey = `image_${placeholderIndex}`;
  
      if (match.index > lastIndex) {
        elements.push(remainingText.slice(lastIndex, match.index));
      }
  
      if (question[imageKey] && images[`${question.guid}_${imageKey}`]) {
        const img = images[`${question.guid}_${imageKey}`];
        const dimensions = getKonvaImageDimensions(img);
  
        elements.push(
          <Box id={`snapshot-container-${questionGuid}`} key={`${questionGuid}_image_${placeholderIndex}`} position="relative">
            <Stage
              ref={(el) => (stageRefs.current[questionGuid] = el)}
              width={dimensions.width}
              height={dimensions.height}
              onMouseDown={(e) => {
                handleMouseDown(e, questionGuid);
                handleErase(e, questionGuid);
              }}
              onMouseMove={(e) => handleMouseMove(e, questionGuid)}
              onMouseUp={() => handleMouseUp(questionGuid)}
              onClick={(e) => handleCanvasClick(e, questionGuid)}
            >
              <Layer>
                <KonvaImage image={img} width={dimensions.width} height={dimensions.height} globalCompositeOperation="source-over" />
                {(questionLines || []).map((line, i) => (
                  <Line
                    key={i}
                    points={line.points}
                    stroke="#df4b26"
                    strokeWidth={line.strokeWidth || 1}
                    tension={0.5}
                    lineCap="round"
                    globalCompositeOperation={line.tool === "eraser" ? "destination-out" : "source-over"}
                  />
                ))}
              </Layer>
            </Stage>
  
            {/* Render TextBox as HTML overlay */}
            {annotations[questionGuid]?.map((annotation) => (
              <TextBox
                key={annotation.id}
                annotation={annotation}
                handleContentChange={(content) => handleContentChange(content, annotation.id, questionGuid)}
                handleDelete={() => handleDelete(annotation.id, questionGuid)}
                updateAnnotationPosition={(position) => updateAnnotationPosition(position, annotation.id, questionGuid)}
              />
            ))}
  
            {isAnnotating[questionGuid] && (
              <AnnotateSubmitMenu
                questionParts={questionParts}
                handleSnapshot={handleSnapshot}
                questionGuid={questionGuid}
                onSelectPart={handleSelectPart}
              />
            )}
          </Box>
        );
      }
  
      lastIndex = imagePlaceholderRegex.lastIndex;
    }
  
    if (lastIndex < remainingText.length) {
      elements.push(remainingText.slice(lastIndex));
    }
  
    return elements;
  };
  
  const handleSnapshot = async (questionGuid, partGuid) => {
    console.log("Snapshot triggered for question:", questionGuid, "and part:", partGuid);
    
    const snapshotContainer = document.getElementById(`snapshot-container-${questionGuid}`);
    if (!snapshotContainer) {
      console.error("No snapshot container found for question:", questionGuid);
      return;
    }

   
    
  
    // Use html2canvas to capture the area with both canvas and text annotations
    const canvas = await html2canvas(snapshotContainer, { scale: 2 });
    const dataURL = canvas.toDataURL("image/png");
  
    // Convert the data URL to a Blob and then to a File
    const blob = await (await fetch(dataURL)).blob();
    const file = new File([blob], `annotated-${questionGuid}.png`, { type: "image/png" });
  
    console.log("Storing snapshot for question:", questionGuid, "and part:", partGuid, "with dataURL:", dataURL);
  
    // Update state for the specific QuestionAnswerBox directly
    setAnswers((prevAnswers) => {
      const currentPartAnswers = prevAnswers[questionGuid] || {};
  
      if (currentPartAnswers[partGuid]) {
        console.log("Snapshot already exists for this part, overwriting.");
      }
  
      const updatedAnswers = {
        ...prevAnswers,
        [questionGuid]: {
          ...currentPartAnswers,
          [partGuid]: { imageFile: file, dataURL }, // Storing both the file and dataURL
        },
      };
  
      return updatedAnswers;
    });
  
    // If the `selectedPartGuid` matches the `partGuid`, pass it to the `QuestionAnswerBox` immediately.
    if (selectedPartGuid === partGuid) {
      setSelectedPartSnapshot(dataURL);  // Create a new state variable `selectedPartSnapshot`
    }

    

    setIsAnnotating((prevState) => ({ ...prevState, [questionGuid]: false }));
  };
  
  const handleAnswerSubmit = async (questionGuid, answer, uploadedImages) => {
    setLoadingFeedback((prev) => ({ ...prev, [questionGuid]: true }));
    const formData = new FormData();
    const startTime = new Date().toISOString();
    const endTime = new Date().toISOString();

    const answerData = {
      completion_status: "COMPLETE",
      start_date_time: startTime,
      updated_date_time: endTime,
      user_answer_text: answer,
      user_answer_image: "",
      feedback_mark: 0,
      feedback_text: "",
      feedback_correctness: "",
      guid: questionGuid,
      user_paper_attempt_guid: paper_Guid,
      question_guid: questionGuid,
    };

    formData.append("user_question_attempt_str", JSON.stringify(answerData));

    if (uploadedImages.length > 0) {
      try {
        await Promise.all(
          uploadedImages.map(async (image) => {
            const file = await fetch(image.url).then((res) => res.blob());
            formData.append("images", file);
          })
        );
      } catch (error) {
        console.error("Error uploading images:", error);
        return;
      }
    } else {
      formData.append("images", new File([], "", { type: "image/png" }));
    }

    try {
      const response = await fetch(
        `${BASE_API_URL}/api/v1/user-question-attempts/submit-answer`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );

      const feedback = await response.json();
      if (feedback) {
        setFeedbackData((prevData) => ({
          ...prevData,
          [questionGuid]: {
            mark: feedback.feedback_mark || 0,
            feedback: feedback.feedback_text || "",
            feedbackStatus: feedback.feedback_correctness || "Unknown",
            sampleImage: feedback.sample_image || "no image url",
            guid: feedback.guid,
            userType: feedback.permission_access
          },
        }));
      } else {
        console.error("Unexpected feedback structure:", feedback);
      }
    } catch (error) {
      console.error("Error submitting answer:", error);
    }
    finally {
      setLoadingFeedback((prev) => ({ ...prev, [questionGuid]: false }));

    }
  };

  const handleSubmitFullPaper = async () => {
    const questionAnswers = questions.map((question) => ({
      completion_status: "COMPLETE",
      start_date_time: new Date().toISOString(),
      updated_date_time: new Date().toISOString(),
      user_answer_text: answers[question.guid] || "",
      user_answer_image: "",
      feedback_mark: 0,
      feedback_text: "",
      feedback_correctness: "",
      guid: "",
      user_paper_attempt_guid: paper_Guid,
      question_guid: question.guid,
    }));

    try {
      const response = await fetch(
        `${BASE_API_URL}/api/v1/user-question-attempts/submit-answers`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(questionAnswers),
        }
      );

      const feedback = await response.json();
      const updatedFeedbackData = {};
      feedback.forEach((feedbackQuestion) => {
        updatedFeedbackData[feedbackQuestion.guid] = {
          mark: feedbackQuestion.feedback_mark,
          feedback: feedbackQuestion.feedback_text,
          feedbackStatus: feedbackQuestion.feedback_correctness,
          sampleImage: feedback.sample_image || "no image url",
        };
      });
      setFeedbackData(updatedFeedbackData);
    } catch (error) {
      console.error("Error submitting paper:", error);
    }
  };

  const SubmitStyleButton = {
    padding: "8px 14px",
    alignItems: "center",
    borderRadius: "14px",
    background: "#4BFF5C",
    color: "#08250B",
    fontSize: "14px",
    fontWeight: "400",
    boxShadow: "0px 0px 5.9px 0px rgba(0, 0, 0, 0.08)",
    width: "194px",
    transition: "background 0.3s, color 0.3s",
    _hover: {
      background: "transparent",
      color: "#021A04",
    },
    zIndex: "999",
    top: "80%",
    right: "35px",
    position: "fixed",
  };

  const onTimeUp = async () => {
    console.log("Time is up! Submitting all answers."); // For verification
    setTimeUp(true); // Set timeUp to true, indicating the test has ended

    // Automatically submit all answers when time is up
    for (const question of questions) {
      const answer = answers[question.guid] || ""; // Use saved answer or default
      const uploadedImages = answers[question.guid]?.uploadedImages || [];
      await handleAnswerSubmit(question.guid, answer, uploadedImages);
    }
  };

  const generateQuestionParts = (questions, questionNumber) => {
    // Filter questions based on the number and group parts with letter and letter_part
    const parts = questions
      .filter((q) => q.number === questionNumber) // Filter questions by number
      .map((part) => {
        let label = `Question ${part.number}`;
        if (part.letter) {
          label += ` ${part.letter})`; // Append letter like 'a', 'b'
        }
        if (part.letter_part) {
          label += ` ${part.letter_part})`; // Append letter part like 'i', 'ii'
        }
        return {
          guid: part.guid, // Ensure part's guid is used for uniqueness
          label,
        };
      });
  
    return parts;
  };
  
  let currentQuestionNumber = null;

  const loadExistingAnswers = (question) => {
    const existingAnswerText = question?.user_question_attempt?.user_answer_text || "";
  
    const existingImages = question?.user_question_attempt?.user_question_attempt_attachments?.map(
      (attachment) => attachment.file_url
    ) || [];
    return { existingAnswerText, existingImages };
  };

  const parseAnswerFormat = (answerFormat) => {
    if (answerFormat.includes("[DISPLAY OPTIONS]")) {
      // Return static options A, B, C, D for `[DISPLAY OPTIONS]`
      return [
        { label: "A", text: "" },
        { label: "B", text: "" },
        { label: "C", text: "" },
        { label: "D", text: "" },
      ];
    }
  
    // Default behavior for other formats
    const regex = /\[(\w+)\](.*?)(?=\[\w+\]|$)/gs;
    const options = [];
    let match;
  
    while ((match = regex.exec(answerFormat)) !== null) {
      options.push({ label: match[1], text: match[2].trim() });
    }
  
    return options;
  };
  

  const handleOptionSelect = (questionGuid, selectedOption) => {
    setSelectedOption((prev) => ({
      ...prev,
      [questionGuid]: selectedOption,
    }));
  };

  
  const handleSubmitOption = (questionGuid) => {
    const selected = selectedOption[questionGuid];
    if (!selected) {
      alert("Please select an option before submitting!");
      return;
    }
  
    setLoadingFeedback((prev) => ({ ...prev, [questionGuid]: true }));

    // Prevent re-submitting the same answer
    if (submittedOptions[questionGuid]) {
      console.log(`Answer for question ${questionGuid} has already been submitted.`);
      return;
    }
    
    

    console.log(`Submitting answer for question ${questionGuid}: ${selected}`);
    handleAnswerSubmit(questionGuid, selected, []);
    
    // Mark question as submitted
    setSubmittedOptions((prev) => ({
      ...prev,
      [questionGuid]: true,
    }));
  }; 
  return (
    <Box>
      <SolveHeader
        subjectName={subject_name}
        paperName={formattedPaperName}
        timeHour={time_hour}
        timeMin={time_min}
        onTimeUp={onTimeUp}
        setTestStarted={setTestStarted}
        position="fixed"
        top="0"
        width="100%"
        zIndex="999" />
      <ToolBar 
        onAddText={handleAddText} 
        onAnnotate={handleAnnotate} 
        onErase={handleEraseTool} 
        activeTool={tool}
        onStrokeWidthChange={handleStrokeWidthChange}
        onResetCursor={handleResetCursor}
      />
      <Flex justifyContent="center" alignItems="center" paddingLeft="12px" width="55%" margin="auto" overflowY="visible">
        <Box position="relative" width="100%" marginTop="154px">
          <Box position="relative" zIndex={8}>
            {questions.map((question, index) => {
              const feedbackForQuestion = feedbackData[question.guid];
              const questionLines = lines[question.guid] || [];
              const questionAnnotations = annotations[question.guid] || [];
              const totalMark = question.total_mark || 0;

              const { existingAnswerText, existingImages } = loadExistingAnswers(question);
              const questionParts = generateQuestionParts(questions, question.number, question.letter);

              let displayQuestionNumber = false;
              if (currentQuestionNumber !== question.number) {
                currentQuestionNumber = question.number;
                displayQuestionNumber = true;
              }

              // Parse answer format to check for multiple-choice options
              const options = question.answer_format ? parseAnswerFormat(question.answer_format) : [];


              return (
                <Box key={index} width="100%" padding={4} borderRadius="4px" position="relative" mb={12}>
                  <Flex direction="column" gap={4} width="100%">
                    {displayQuestionNumber && (
                      <Text fontSize="16px" fontWeight="600">
                        {`${question.number})`}
                      </Text>
                    )}

                    <Flex justifyContent="space-between" alignItems="center" width="100%">
                      <Text fontSize="14px" fontWeight="500">
                        {question.letter && `${question.letter}) `}
                        {question.letter_part && `${question.letter_part})`}
                      </Text>
                      {totalMark > 0 && (
                        <Box
                          width="58px"
                          borderRadius="4px"
                          background="#F1F1F1"
                          padding="8px"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Text fontSize="12px" fontWeight="400" lineHeight="normal" color="#021A04">
                            {totalMark} mark
                          </Text>
                        </Box>
                      )}
                    </Flex>

                    <Text fontSize="14px" fontWeight="400" mt={2}>
                      {processQuestionTextWithImages(
                        question.question_text,
                        question,
                        question.guid,
                        stageRefs,
                        handleMouseDown,
                        handleMouseMove,
                        handleMouseUp,
                        tool,
                        questionLines,
                        questionAnnotations,
                        isAnnotating,
                        handleSnapshot,
                        questionParts
                      )}
                    </Text>

                    {options.length > 0 ? (
                      // Render multiple-choice options if parsed
                      <Box mt={4}>
                        <RadioGroup
                          onChange={(value) => handleOptionSelect(question.guid, value)}
                          value={selectedOption[question.guid]}
                        >
                          <Stack direction="column">
                            {options.map((option) => (
                              <Radio
                                key={option.label}
                                value={option.label}
                                sx={{
                                  "&.chakra-radio__control": {
                                    borderColor: "gray", // Default border color
                                    backgroundColor: "white", // Default background color
                                  },
                                  "&.chakra-radio__control[data-checked]": {
                                    backgroundColor: "#4BFF5C", // Fill color when selected
                                    borderColor: "#4BFF5C", // Border color when selected
                                  },
                                  "&.chakra-radio__label": {
                                    color: "#021A04",
                                    fontWeight: "400",
                                    fontSize: "14px",
                                  },
                                }}
                              >
                                {`${option.label} ${option.text}`}
                              </Radio>
                            ))}
                          </Stack>
                        </RadioGroup>
                        {!testStarted && !timeUp && (
                        <Button
                          padding="8px 18px"
                          alignItems="center"
                          gap="4px"
                          borderRadius="14px"
                          background="var(--Main-font-color, #021A04)"
                          color="var(--Primary, #F1F1F1)"
                          fontSize="14px"
                          fontWeight="400"
                          mt={9}
                          onClick={() => handleSubmitOption(question.guid)}
                          onMouseEnter={() => setSubmitIcon(SubmitIconDark)}
                          onMouseLeave={() => setSubmitIcon(SubmitIcon)}
                          _hover={{
                            background: "var(--Primary, #F1F1F1)",
                            color: "var(--Main-font-color, #021A04)",
                            boxShadow: "0px 0px 5.9px 0px rgba(0, 0, 0, 0.14)",
                          }}
                        >
                          <Image src={submitIcon} alt="Submit Answer" />
                          Submit Answer
                        </Button>
                      )}

                      </Box>
                    ) : (
                      totalMark > 0 && (  // Conditionally render the QuestionAnswerBox based on totalMark
                      <Box mt={8} zIndex={10}>
                        <QuestionAnswerBox
                          questionNum={question.guid}
                          existingAnswerText={existingAnswerText}
                          existingImages={existingImages}
                          handleSubmitAnswer={handleAnswerSubmit}
                          answerFormat={question.answer_format}
                          snapshot={selectedPartGuid === question.guid ? selectedPartSnapshot : null} // Pass snapshot only if it matches the selected part
                          selectedPartGuid={selectedPartGuid}
                          tool={tool}
                          strokeWidth={strokeWidth}
                          timeUp={timeUp}
                          testStarted={testStarted}
                          setLoading={(isLoading) => setLoadingFeedback((prev) => ({ ...prev, [question.guid]: isLoading }))}
                          loading={loadingFeedback[question.guid]}
                        />


                      </Box>
                    ))}

                  </Flex>

                  {feedbackForQuestion && (
                    <FeedBack
                     loading={loadingFeedback[question.guid] || false}
                      feedbackData={feedbackForQuestion}
                      questionAttemptGuid={question?.user_question_attempt?.guid || feedbackForQuestion.guid || null}
                      totalMarks={totalMark}
                      sampleImage={question?.user_question_attempt?.sample_image || feedbackForQuestion.sampleImage || null}
                      userType = {question?.user_question_attempt?.permission_access || feedbackForQuestion.permission_access|| null}
                    />
                  )}

                </Box>
              );
            })}
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default SolvePaper;
