import React from 'react';
import { Box, Heading, Text, VStack } from '@chakra-ui/react';

const PrivacyPolicy = () => {
  return (
    <Box p={8} maxW="800px" mx="auto">
      <VStack spacing={6} align="start">
        <Heading as="h1" size="xl" color="#021A04">
          Privacy Policy
        </Heading>
        <Text fontSize="lg" color="#08250B">
          Last updated: August 2024
        </Text>
        <Text fontSize="md" color="#08250B">
          This Privacy Policy describes how we collect, use, and share information when you use our website and services. We are committed to protecting your privacy and ensuring the security of your personal information.
        </Text>

        <Heading as="h2" size="lg" color="#021A04">
          Information We Collect
        </Heading>
        <Text fontSize="md" color="#08250B">
          We collect information you provide directly to us when you create an account, use our services, or interact with our website. This may include your name, email address, and any other details necessary for authentication and providing our services.
        </Text>

        <Heading as="h2" size="lg" color="#021A04">
          How We Use Your Information
        </Heading>
        <Text fontSize="md" color="#08250B">
          We use the information we collect to:
        </Text>
        <VStack align="start" spacing={2} pl={4}>
          <Text fontSize="md" color="#08250B">- Provide and improve our services.</Text>
          <Text fontSize="md" color="#08250B">- Communicate with you regarding updates, promotions, and support.</Text>
          <Text fontSize="md" color="#08250B">- Ensure security and prevent fraud.</Text>
        </VStack>

        <Heading as="h2" size="lg" color="#021A04">
          Sharing Your Information
        </Heading>
        <Text fontSize="md" color="#08250B">
          We do not share your personal information with third parties except as necessary to provide our services, comply with legal obligations, or with your explicit consent.
        </Text>

        <Heading as="h2" size="lg" color="#021A04">
          Your Rights and Choices
        </Heading>
        <Text fontSize="md" color="#08250B">
          You have the right to access, update, or delete your personal information. You may also opt out of marketing communications at any time.
        </Text>

        <Heading as="h2" size="lg" color="#021A04">
          Changes to This Privacy Policy
        </Heading>
        <Text fontSize="md" color="#08250B">
          We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new policy on this page.
        </Text>

        <Heading as="h2" size="lg" color="#021A04">
          Contact Us
        </Heading>
        <Text fontSize="md" color="#08250B">
          If you have any questions or concerns about this Privacy Policy, please contact us at support@yourdomain.com.
        </Text>
      </VStack>
    </Box>
  );
};

export default PrivacyPolicy;
