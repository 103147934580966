import React, { useState } from "react";
import { Box, Flex, VStack, Heading, Text, Button, Image } from "@chakra-ui/react";
import ProductImage from "./ProductImage.svg";
import ExpandArrow from "./ExpandArrow.svg";
import CollapseArrow from "./CollapseArrow.svg";
import ArrowButton from "./CallToActionArrowButton.svg";

const Product = () => {
  const [expandedStep, setExpandedStep] = useState(null);

  const steps = [
    {
      title: "1. Choose Your Exam Board & Qualification",
      description: "Whether you're preparing for Cambridge IGCSE, Edexcel GCSE, or AQA A-levels, select your board and qualification in one click.",
    },
    {
      title: "2. Solve Past Papers",
      description: "Get access to hundreds of past papers to practice with and improve your skills.",
    },
    {
      title: "3. Get Instant Feedback",
      description: "Receive AI-driven feedback on your answers with tips on how to improve.",
    },
    {
      title: "4. Track Your Progress",
      description: "Monitor your performance over time and identify areas for improvement.",
    },
    {
      title: "5. Personalized Study Suggestions",
      description: "Get tailored recommendations to focus on topics where you need the most help.",
    },
  ];

  const toggleExpand = (index) => {
    setExpandedStep(expandedStep === index ? null : index);
  };

  return (
    <Box width="100%" paddingX={{ base: "20px", md: "45px" }} marginTop="60px">
      <Flex
        flexDirection={{ base: "column", lg: "row" }}  // Stack on small screens, row on large
        gap={{ base: "40px", lg: "60px" }}  // Adjust gap for smaller and larger screens
        alignItems="flex-start"
        justifyContent="space-between"
      >
        {/* Left Section: Text and Image */}
        <VStack
          alignItems="flex-start"
          width={{ base: "100%", lg: "50%" }}  // Full width on mobile, half on large screens
          gap="14px"
        >
          <Heading color="#021A04" fontSize={{ base: "24px", md: "32px" }} fontWeight="600">
            How We Help You Succeed
          </Heading>
          <Text color="#021A04" fontSize={{ base: "14px", md: "16px" }} fontWeight="400">
            Our platform simplifies exam preparation with easy access to past papers, instant feedback, and powerful tools to track your performance.
          </Text>
          <Button variant="unstyled" textDecoration="underline">
            <Flex gap="4px" alignItems="center" justifyContent="flex-start">
              <Heading color="#021A04" fontSize="12px" fontWeight="400">
                Try it for Free
              </Heading>
              <Image src={ArrowButton} alt="get-started-arrow-button-icon" />
            </Flex>
          </Button>
          <Image src={ProductImage} alt="product-image" width="100%" /> {/* Responsive image */}
        </VStack>

        {/* Right Section: Expandable Steps */}
        <VStack
          width={{ base: "100%", lg: "45%" }}  // Full width on mobile, smaller on larger screens
          spacing="14px"
        >
          {steps.map((step, index) => (
            <Box
              key={index}
              padding="15px 10px"
              backgroundColor={expandedStep === index ? "#0D0D0D" : "#111111"}
              color={expandedStep === index ? "#AEAEAE" : "#F1F1F1"}
              cursor="pointer"
              onClick={() => toggleExpand(index)}
              borderRadius="14px"
              transition="background-color 0.3s ease-in-out"
              width="100%"  // Ensure full width for boxes
            >
              <Flex justifyContent="space-between" alignItems="center">
                <Heading fontSize="12px" fontWeight="400" color="#F1F1F1">
                  {step.title}
                </Heading>
                <Image
                  src={expandedStep === index ? CollapseArrow : ExpandArrow}
                  alt="expand-collapse-arrow"
                />
              </Flex>
              {expandedStep === index && (
                <Text color="#AEAEAE" fontSize="12px" fontWeight="400" marginTop="10px">
                  {step.description}
                </Text>
              )}
            </Box>
          ))}
        </VStack>
      </Flex>
    </Box>
  );
};

export default Product;
