// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import Login from './Components/Login';
import PrivacyPolicy from './Components/Privacypolicy';
import CreateAccount from './Components/CreateAccount';
import Home from './Components/Home';
import SolvePaper from './Components/SolvePaper';
import DashboardMain from './Components/DashboardMain';
import MyPaper from './Components/MyPaper';
import MySubjects from './Components/MySubjects';
import OnePageSite from './Components/Landing Page/OnePageSite';
import Layout from './Components/Layout';
import Settings from './Components/settings';
import PrivateRoute from "./Components/PrivateRoute";


function App() {
  return (
    <Router>
      <Box>
        <Routes>
          {/* Routes that do not require Sidebar or Header */}
          <Route path="/login" element={<Login/>} />
          <Route path="/" element={<OnePageSite />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/create-account" element={<CreateAccount />} />
          <Route path="/solve-paper" element={<SolvePaper />} />
          <Route path="/my-papers/solve-paper" element={<SolvePaper />} />
          {/* Routes wrapped with Sidebar and Header using Layout */}
          <Route
            path="/home"
            element={
              <Layout>
                <PrivateRoute>
                  <Home />
                </PrivateRoute>

              </Layout>
            }
          />
          <Route
            path="/dashboard"
            element={
              <Layout>
                <PrivateRoute>
                  <DashboardMain />
                </PrivateRoute>

              </Layout>
            }
          />
         <Route
            path="/my-papers"
            element={
              <Layout>
                <PrivateRoute>
                  <MyPaper />
                </PrivateRoute>
              </Layout>
            }
          />


          <Route
            path="/my-subjects"
            element={
              <Layout>
                <PrivateRoute>
                  <MySubjects />
                </PrivateRoute>
              </Layout>
            }
          />
          <Route
            path="/settings"
            element={
              <Layout>
                <PrivateRoute>
                  <Settings />
                </PrivateRoute>
              </Layout>
            }
          />
        </Routes>
      </Box>
    </Router>
  );
}

export default App;
