import React, { useState, useEffect } from "react";
import { Box, Text, Flex, Menu, MenuButton, MenuList, MenuItem, Button, Image, Heading } from "@chakra-ui/react";
import MenuArrow from "../Images/Menu_Arrow.svg";
import Arrow from "./Landing Page/CallToActionArrowButton.svg";
import axios from "axios";
import { BASE_API_URL } from './config';

const SubjectTopicTracker = () => {
  const [selectedSubjectGuid, setSelectedSubjectGuid] = useState(null);
  const [selectedSubjectName, setSelectedSubjectName] = useState("Subject");
  const [subjects, setSubjects] = useState([]);
  const [topicsThatNeedAttention, setTopicsThatNeedAttention] = useState ([]);
  const [topics, setTopics] = useState([]);
  const [totalQuestionsAttempted, setTotalQuestionsAttempted] = useState(0);
  const [activeTopic, setActiveTopic] = useState(null);
  const [permissionAccess, setPermissionAccess] = useState(null);
  const accessToken = localStorage.getItem("accessToken");

  useEffect(() => {
    const fetchSubjects = async () => {
      try {
        const response = await axios.get(`${BASE_API_URL}/api/v1/user-subject-links/own/detail`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setSubjects(response.data);
      } catch (err) {
        console.error("Failed to fetch subjects.", err);
      }
    };

    fetchSubjects();
  }, [accessToken]);

  useEffect(() => {
    const fetchTrackingData = async () => {
      if (!selectedSubjectGuid) return;

      try {
        const response = await axios.get(
          `${BASE_API_URL}/api/v1/tracking/user-subject-topic/${selectedSubjectGuid}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        const trackingData = response.data;
        setTotalQuestionsAttempted(trackingData.total_question_attempted_count);
        const uniqueTopics = trackingData.subject_topics.filter((topic, index, self) =>
          index === self.findIndex(t => t.name === topic.name)
        ); 
        setTopics(uniqueTopics);
        setPermissionAccess(trackingData.permission_access);
        setTopicsThatNeedAttention(trackingData.least_attempted_topics); 
        
      } catch (err) {
        console.error("Failed to fetch tracking data.", err);
      }
    };

    if (selectedSubjectGuid) {
      fetchTrackingData();
    }
  }, [selectedSubjectGuid, accessToken]);


  const handleUpgradeClick = async () => {
    const token = `Bearer ${localStorage.getItem('accessToken')}`;

    try {
      // Fetch the user's active_subscription_plan_guid
      const userResponse = await fetch(`${BASE_API_URL}/api/v1/users/own`, {
        headers: {
          Authorization: token,
        },
      });

      if (!userResponse.ok) {
        throw new Error('Failed to fetch user data');
      }

      const userData = await userResponse.json();
      //const activeSubscriptionPlanGuid = userData.active_subscription_plan_guid;

      const checkoutResponse = await fetch(`${BASE_API_URL}/api/v1/user-subscription-plans/create-checkout-session`, {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({
          subscription_plan_guid: "298eb053-7c3a-45d6-967f-f73508517aa9",
          stripe_price_id: "price_1QAxtACNup0MY3lGGNHg4nbg",
          renewal_type: "MONTHLY",
        }),
      });

      if (!checkoutResponse.ok) {
        throw new Error('Failed to create checkout session');
      }

      const checkoutData = await checkoutResponse.json();
      const checkoutUrl = checkoutData.checkout_url;

      // Redirect user to the checkout page
      window.location.href = checkoutUrl;
    } catch (err) {
      console.error('Error creating checkout session:', err.message);
    }
  };

  const handleSubjectSelect = (subject) => {
    setSelectedSubjectGuid(subject.guid);
    setSelectedSubjectName(subject.subject_name);
  };

  const handleHover = (index) => {
    setActiveTopic(index);
  };

  const handleClick = (index) => {
    setActiveTopic(index);
  };

  const handleMouseLeave = () => {
    setActiveTopic(null);
  };

  return (
    <Box
      width="869px"
      height="400px"
      border="2px solid #E7EAE8"
      borderRadius="14px"
      p="20px"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      overflow="hidden"
      position="relative"
    >
      {/* Title and total questions container */}
      <Flex justifyContent="space-between" alignItems="flex-start" height="180px">
        <Box display="flex" flexDirection="column" justifyContent="space-between">
          <Text color="#000" fontSize="20px" fontWeight="400">
            Topics to focus on
          </Text>
        </Box>

        {/* Subject selector */}
        <Flex flexDirection="column" alignItems="flex-end" width="410px">
          <Menu>
            <MenuButton
              as={Button}
              variant="menuButton"
              width="122px"
              height="28px"
              fontSize="14px"
              fontWeight="400"
              background="white"
              border="2.333px solid #E7EAE8"
              borderRadius="9.333px"
              _hover={{ background: "white" }}
              _focus={{ boxShadow: "none" }}
              rightIcon={<img src={MenuArrow} alt="menu arrow" style={{ marginLeft: "8px" }} />}
            >
              {selectedSubjectName}
            </MenuButton>
            <MenuList background="white" color="#021A04" textAlign="center" fontSize="12px" fontWeight="300">
              {subjects.map((subject, index) => (
                <MenuItem key={index} onClick={() => handleSubjectSelect(subject)}>
                  {subject.subject_name}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </Flex>
      </Flex>

      {/* Blurring and upgrade section starts here */}
      <Box position="relative" mt="20px">
  <Box
    filter={permissionAccess === "LIMITED" ? "blur(5px)" : "none"}
    position="relative"
    zIndex="1"
    width="100%"
    height="300px"
  >
    {/* Conditional text based on subject selection */}
    <Text color="#535953" fontSize="12px" fontWeight="200" mt="5px" width="320px">
      {selectedSubjectGuid ? (
        <>
          Based on the questions you solved so far in the{" "}
          <Text as="span" fontWeight="bold">{selectedSubjectName}</Text>, you should focus on{" "}
          <Text as="span" fontWeight="bold">{topicsThatNeedAttention[0]?.name || "..."}</Text>.
        </>
      ) : (
        <>
          Please select a subject to learn more about the topics that need your attention currently in the{" "}
          <Text as="span" fontWeight="bold">{selectedSubjectName}</Text>.
        </>
      )}
    </Text>

    {/* Graph and topic details container */}
    <Flex justifyContent="space-between" alignItems="flex-end" width="100%" height="100%" >
      {topics.map((topic, index) => (
        <Box
          key={index}
          display="flex"
          flexDirection="column"
          alignItems="center"
          position="relative"
          onMouseEnter={() => handleHover(index)}
          onMouseLeave={handleMouseLeave}
          cursor="pointer"
          marginBottom = "24px"
        >
          {activeTopic === index && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="70px"
              padding="10px"
              borderRadius="100px"
              background="#021A04"
              color="#F9FBF9"
              fontSize="9px"
              fontWeight="400"
              position="absolute"
              top="-35px"
            >
              {topic.questions_answered_count} Questions
            </Box>
          )}
          <Box
            width="66px"
            height="221px"
            borderRadius="0px 0px 24px 24px"
            background={activeTopic === index ? "linear-gradient(180deg, #FFF 11.68%, #E7EAE8 100%)" : "transparent"}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="flex-end"
            padding="8px"
          >
            <Box as="svg" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 10 10" fill="none" mb="-7px">
              <circle cx="5" cy="5" r="5" fill="#4BFF5C" />
            </Box>
            <Box
              width="2px"
              height={`${topic.questions_answered_count * 15}px`}
              background="#D7E3D9"
              mb="10px"
            ></Box>
            <Box
              width="40px"
              height="40px"
              background="#021A04"
              borderRadius="100px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              color="#F9FBF9"
              fontSize="12px"
              fontWeight="400"
              mb="8px"
            >
              {topic.name.charAt(0)}
            </Box>
          </Box>
          <Box>
          <Text fontSize="9px" fontWeight="300" color="#021A04" textAlign="center" >
            {topic.name}
          </Text>

          </Box>
        </Box>
        
      ))}
    </Flex>

  </Box>

  {/* Blurring and upgrade section */}
  {permissionAccess === "LIMITED" && (
    <Box
      position="absolute"
      top="0"
      left="0"
      right="0"
      bottom="0"
      bg="rgba(255, 255, 255, 0.7)"
      backdropFilter="blur(2px)"
      display="flex"
      justifyContent="center"
      alignItems="center"
      borderRadius="14px"
      zIndex="2"
      padding="20px"
    >
      <Box textAlign="center">
        <Text fontSize="16px" mb="12px">
          Upgrade to premium to access this
        </Text>
        <Button
          variant="unstyled"
          borderRadius="14px"
          bg="#4BFF5C"
          width="180px"
          border="1px solid"
          borderColor="#4BFF5C"
          color="#021A04"
          _hover={{
            color: "#021A04",
            borderColor: "#F1F1F1",
            backgroundColor: "transparent",
          }}
          onClick={handleUpgradeClick}
        >
          <Flex padding="8px 20px" gap="4px" alignItems="center" justifyContent="center">
            <Heading fontSize="12px" fontWeight="400">
              Upgrade to premium
            </Heading>
            <Image src={Arrow} alt="get-started-arrow-button-icon" />
          </Flex>
        </Button>
      </Box>
    </Box>
  )}
  </Box>

    </Box>
  );
};

export default SubjectTopicTracker;
