import React from "react";
import { Box, Image, Flex, Button, HStack, Heading, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import HeroImage from "./HeroImage.svg";
import Arrow from "./CallToActionArrowButton.svg";
import CambridgeLogo from "./CambridgeLogo.svg";
import EdexcelLogo from "./EdexcelLogo.svg";
import OCRLogo from "./OCRLogo.svg";
import AQALogo from "./AQAlogo.svg";

const Hero = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  // Handle navigation to /login
  const handleLoginClick = () => {
    navigate("/login");
  }; 

  return (
    <Box width="100%" bg="#ffffff" paddingY={{ base: "50px", md: "100px" }}>
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width="100%"
        //maxWidth="1287px"
        margin="auto"
        paddingX="45px" // Responsive horizontal padding
        gap="50px" // Spacing between sections
      >
        {/* Text and Image Container */}
        <Flex
          flexDirection={{ base: "column", lg: "row" }} // Stack on mobile, row on larger screens
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          marginTop={{ base: "45px", lg: "100px" }} // Add margin to push image down
        >
          {/* Text Section */}
          <Flex
            flexDirection="column"
            width={{ base: "100%", lg: "50%" }} // Full width on mobile, 50% on large screens
            textAlign={{ base: "center", lg: "left" }} // Center text on mobile
            gap="20px"
          >
            <Heading
              color="#282A29"
              fontSize={{ base: "24px", md: "32px" }} // Responsive font size
              fontWeight="600"
            >
              Ace Your Exams with Instant{" "}
              <Text as="span" color="#282A29" textDecoration = "underline" >
                AI Feedback
              </Text>{" "}
              and{" "}
              <Text as="span" color="#282A29" textDecoration = "underline">
                Personalized
              </Text>{" "}
              Study Tools
            </Heading>
            <Text
              color="#424443"
              fontSize={{ base: "14px", md: "16px" }} // Adjust font size for mobile
              fontWeight="400"
            >
              Solve past papers from Cambridge, Edexcel, OCR, and AQA. Get precise,
              detailed feedback on every answer, track your progress, and focus on
              the topics that matter most to your success.
            </Text>
            <Button
              variant="unstyled"
              borderRadius="14px"
              bg="#4BFF5C"
              width="201px"
              border="1px solid"
              borderColor="#4BFF5C"
              color="#021A04"
              _hover={{
                color: "#282A29",
                borderColor: "#282A29",
                backgroundColor: "transparent",
              }}
              margin={{ base: "auto", lg: "0" }} // Center button on mobile
              onClick = {handleLoginClick}
            >
              <Flex
                padding="10px 34px"
                gap="4px"
                alignItems="center"
                justifyContent="center"
              >
                <Heading fontSize="12px" fontWeight="400">
                  Get Started For Free
                </Heading>
                <Image src={Arrow} alt="get-started-arrow-button-icon" />
              </Flex>
            </Button>
          </Flex>

          {/* Hero Image */}
          <Box
            width={{ base: "100%", lg: "auto" }}
            marginTop={{ base: "40px", lg: "0" }}
            marginRight={{ lg: "-8px" }} // Align the image left with the Login button
          >
            <Image
              src={HeroImage}
              alt="hero-image"
              width={{ base: "100%", md: "auto" }}
              maxWidth="100%" // Ensure image is responsive
            />
          </Box>
        </Flex>

        {/* Logos Section */}
        <Flex
          alignItems="center"
          justifyContent="space-around"
          width="100%"
          gap={{ base: "25px", lg: "145px" }} // Adjust spacing for logos
          marginTop="45px"
        >
          <Image src={CambridgeLogo} alt="cambridge-logo" width={{ base: "60px", lg: "auto" }} />
          <Image src={EdexcelLogo} alt="edexcel-logo" width={{ base: "60px", lg: "auto" }} />
          <Image src={OCRLogo} alt="ocr-logo" width={{ base: "60px", lg: "auto" }} />
          <Image src={AQALogo} alt="aqa-logo" width={{ base: "60px", lg: "auto" }} />
        </Flex>
      </Flex>
    </Box>
  );
};

export default Hero;
