import React from "react";
import { Box, HStack, Skeleton} from "@chakra-ui/react";

const FeedBackSkeleton = () => {
    return (
      <Box width="548px" padding="24px" borderRadius="14px" mt="24px" bg="#F6F6F6">
        <HStack justify="space-between">
          <Skeleton height="20px" width="100px" mb={4} borderRadius="8px" />
          <Skeleton height="20px" width="60px" borderRadius="8px" />
        </HStack>
        <Skeleton height="20px" width="100%" mt={4} borderRadius="8px" />
        <Skeleton height="20px" width="80%" mt={2} borderRadius="8px" />
      </Box>
    );
  };

export default FeedBackSkeleton;