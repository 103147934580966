import React from "react";
import { Box, Flex, Heading, Image } from "@chakra-ui/react";
import CardArrowIcon from "../Images/cardArrowIcon.svg";
import SeperatorIcon from "../Images/seperatorIcon.svg";

const MySubjectCard = ({ subjectName, examBoardCode, qualificationCode, onClick }) => {
  return (
    <Box
      width="281px"
      height="153px"
      borderRadius="14px"
      background="linear-gradient(240deg, #0FFD26 12.06%, #7BFC87 85.61%)"
      alignItems="left"
      cursor="pointer"
      position="relative"
      padding="10px"
      onClick={onClick}
      _hover={{ borderRadius: "14px", border: "2px solid #E7EAE8", bg: "#ffffff" }}
    >
      <Flex direction="column" flex="1" justify="space-between" padding="10px">
        <Box
          width="154px"
          height="34px"
          background="#021A04"
          borderRadius="24px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          p = "2"
        >
          <Flex flexDirection="row" alignItems="center" gap="4px">
            <Heading color="#F1F1F1" fontSize="12px" fontWeight="400">
              {examBoardCode}
            </Heading>
            <Image src={SeperatorIcon} alt="seperator-icon" />
            <Heading color="#F1F1F1" fontSize="12px" fontWeight="400">
              {qualificationCode}
            </Heading>
          </Flex>
        </Box>

        <Heading color="#021A04" fontSize="20px" fontWeight="400" mt={4}>
          {subjectName}
        </Heading>

        <Box position="absolute" bottom="4px" right="4px">
          <Image src={CardArrowIcon} alt="Card Arrow Icon" />
        </Box>
      </Flex>
    </Box>
  );
};

export default MySubjectCard;
