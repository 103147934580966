import React from "react";
import { Box, Image, Flex, Heading, Text } from "@chakra-ui/react";
import AboutUsImage from "./AboutUsImage.svg";

const AboutUs = () => {
  return (
    <Box width="100%" marginTop="60px" paddingX={{ base: "20px", md: "45px" }}>
      <Flex
        flexDirection={{ base: "column", md: "row" }}
        alignItems="center"
        justifyContent="space-between"
        margin="auto"
        gap="125px"
        paddingTop={{ base: "80px", md: "100px" }} // Adjust top padding for different screens
      >
        {/* Text Section */}
        <Flex flexDirection="column" width={{ base: "100%", md: "50%" }} gap="14px">
          <Heading
            color="#021A04"
            fontSize={{ base: "24px", md: "32px" }}
            fontWeight="600"
            textAlign={{ base: "center", md: "left" }} // Center text on mobile
          >
            Empowering Every Student for Success
          </Heading>
          <Text
            color="#021A04"
            fontSize="12px"
            fontWeight="400"
            textAlign={{ base: "center", md: "left" }} // Center text on mobile
          >
            We believe in creating simple, beautiful solutions that have a big impact. Our mission is
            to support students, teachers, and parents by continuously refining our platform to make
            exam preparation easier, more effective, and less stressful. As an EdTech company, we’re
            committed to innovation that improves education for everyone involved.
          </Text>
        </Flex>

        {/* Image Section */}
        <Flex justifyContent="center" width={{ base: "100%", md: "50%" }}>
          <Image
            src={AboutUsImage}
            alt="about-us-image"
            width={{ base: "80%", md: "auto" }} // Adjust image width for different screen sizes
            borderRadius="full" // Optional: to give the image a circular shape
          />
        </Flex>
      </Flex>
    </Box>
  );
};

export default AboutUs;
