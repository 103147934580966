import React, { useState, useEffect } from 'react';
import {
  Box,
  InputGroup,
  InputLeftElement,
  Input,
  Button,
  Flex,
  HStack,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import SearchIcon from "../Images/Search-Icon.svg";
import NotificationIcon from "../Images/Notification-icon.svg";
import SettingsIcon from "./Settings-icon.svg";
import LogoutIcon from "./Logout-icon.svg";
import { BASE_API_URL } from './config';

const Header = () => {
  // Adjust the search bar width based on screen size
  const inputWidth = useBreakpointValue({ base: '40%', sm: '40%', md: '35%' });
  const [firstLetter, setFirstLetter] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const storedFirstLetter = localStorage.getItem('firstLetter');
    if (storedFirstLetter) {
      setFirstLetter(storedFirstLetter);
    } else {
      setFirstLetter('');
    }
  }, []);

  const handleSearch = (e) => {
    // Handle search functionality here
  };

  const handleLogout = async () => {
    try {
      const response = await axios.post(`${BASE_API_URL}/api/v1/auth/sign-out`);
      if (response.data.msg === "Signed out successfully") {
        localStorage.clear();
        navigate('/login');
      }
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  return (
    <Box
      width="85%"
      padding={{ base: '8px 16px', md: '24px 24px 18px 34px' }}
      position="fixed"
      top="0px"
      left={{ base: '34px', md: '234px' }}
      zIndex="999"
      background="rgba(255, 255, 255, 255)"
    >
      <Flex
        justify="space-between"
        alignItems="center"
        paddingRight={{ base: '8px', md: '54px' }}
      >
        {/* Search Bar */}
        <InputGroup width={inputWidth} marginRight="20px">
          <InputLeftElement pointerEvents="none" height="100%">
            <img src={SearchIcon} alt="search-icon" style={{ marginLeft: '8px' }} />
          </InputLeftElement>
          <Input
            placeholder="Search for your papers to solve here"
            paddingLeft="40px"
            borderRadius="14px"
            backgroundColor="#F7F7F7"
            color="#BABABA"
            onChange={handleSearch}
          />
        </InputGroup>

        {/* Notification Icon and Profile Menu */}
        <HStack spacing={{ base: 2, md: 4 }} alignItems="center">
          {/* Notification Menu */}
          <Menu>
            <MenuButton as={Button} variant="unstyled">
              <img src={NotificationIcon} alt="Notification-icon" style={{ width: '42px' }} />
            </MenuButton>
            <MenuList>
              <MenuItem>
                <Text color="gray.500" fontSize="sm">
                  No notifications yet
                </Text>
              </MenuItem>
            </MenuList>
          </Menu>

          {/* Profile Menu */}
          <Menu>
            <MenuButton
              as={Button}
              variant="unstyled"
              position="relative"
              _hover={{ cursor: 'pointer'}}
            >
              <Box
                width = "39px" 
                height = "39px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                backgroundColor="#021A04"
                borderRadius="50%"
                boxShadow="0px 2px 4px rgba(0, 0, 0, 0.14)"
                flexShrink="0"
              >
                <Text
                  fontSize="15px"
                  fontWeight="500"
                  color="#FFF"
                  textAlign="center"
                  textTransform="uppercase"
                >
                  {firstLetter}
                </Text>
              </Box>
            </MenuButton>
            <MenuList>
              <MenuItem
                icon={<img src={SettingsIcon} alt="settings icon" />}
                onClick={() => navigate('/settings')}
              >
                Settings
              </MenuItem>
              <MenuItem
                icon={<img src={LogoutIcon} alt="logout icon" />}
                onClick={handleLogout}
              >
                Logout
              </MenuItem>
            </MenuList>
          </Menu>
        </HStack>
      </Flex>
    </Box>
  );
};

export default Header;
