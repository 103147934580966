import React, { useState, useEffect } from 'react';
import { Box, Heading, Image, VStack, Flex, Button, IconButton, useBreakpointValue } from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import DashboardIcon from "../Images/Dashboard-icon.svg";
import PastPapers from "../Images/Past-papers-icon.svg";
import MyPapers from "../Images/My-papers-icon.svg";
import MySubjects from "../Images/My-subjects-icon.svg";
import SettingsIcon from "../Images/Settings-icon.svg";
import LogoutIcon from "../Images/Logout-icon.svg";
import logo from "../Images/LOGO.svg";
import { BASE_API_URL } from './config';

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activePath, setActivePath] = useState(location.pathname);
  const [isOpen, setIsOpen] = useState(false);

  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    setActivePath(location.pathname);
  }, [location]);

  const hoverStyle = {
    background: "#070707",
    borderRadius: '0px 24px 24px 0px',
    borderLeft: '1px solid var(--Lime-green-gradient, #0FFD26)',
  };

  const activeButtonStyle = {
    background: "#070707",
    borderRadius: '0px 24px 24px 0px',
    borderLeft: '1px solid var(--Lime-green-gradient, #0FFD26)',
  };

  const handleLogout = async () => {
    try {
      const response = await axios.post(`${BASE_API_URL}/api/v1/auth/sign-out`);
      if (response.data.msg === "Signed out successfully") {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('tokenType');
        localStorage.removeItem('userGuid');
        localStorage.removeItem('subscriptionPlanGuid');
        localStorage.removeItem('firstLetter');
        navigate('/login');
      }
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  return (
    <>
      {isMobile && (
        <IconButton
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          aria-label="Toggle Sidebar"
          position="fixed"
          top="16px"
          left="16px"
          onClick={() => setIsOpen(!isOpen)}
          zIndex="overlay"
          backgroundColor="#070707"
          color="white"
        />
      )}
      
      <Box
        bg="#070707"
        width="234px"
        height="100vh"
        position={isMobile && isOpen ? "fixed" : "relative"}
        left={isMobile && !isOpen ? "-234px" : "0"}
        top="0"
        transition="left 0.3s ease"
        zIndex={isMobile && isOpen ? "overlay" : "auto"}
        onClick={() => isMobile && setIsOpen(false)} // Close on click for mobile
      >
        <Flex paddingLeft="41px" height="100vh">
          <VStack align="start" spacing={4} height="100%">
            <Flex gap="4px" align="center">
              <Image src={logo} alt="Xamer.ai-logo" boxSize="120px" />
            </Flex>
            <Box height="14px" />
            <Heading as="h2" fontSize="14px" fontStyle="normal" fontWeight="350" color="#ffffff">
              Overview
            </Heading>

            <Button
              gap="4px"
              paddingTop="10px"
              paddingBottom="10px"
              width="100%"
              variant="ghost"
              justifyContent="start"
              sx={activePath === '/home' ? activeButtonStyle : { ':hover': hoverStyle }}
              onClick={() => navigate('/home')}
            >
              <Image src={PastPapers} alt="past-paper-icon" />
              <Heading as="h1" fontSize="12px" fontStyle="normal" fontWeight="350" color="#ffffff">
                Past Papers
              </Heading>
            </Button>

            <Button
              gap="4px"
              paddingTop="10px"
              paddingBottom="10px"
              width="100%"
              variant="ghost"
              justifyContent="start"
              sx={activePath === '/dashboard' ? activeButtonStyle : { ':hover': hoverStyle }}
              onClick={() => navigate('/dashboard')}
            >
              <Image src={DashboardIcon} alt="dashboard-icon" />
              <Heading as="h1" fontSize="12px" fontStyle="normal" fontWeight="350" color="#ffffff">
                Dashboard
              </Heading>
            </Button>

            <Box height="25px" />
            <Heading as="h2" fontSize="14px" fontStyle="normal" fontWeight="350" color="#ffffff">
              My Workspace
            </Heading>

            <Button
              gap="4px"
              paddingTop="10px"
              paddingBottom="10px"
              width="100%"
              variant="ghost"
              justifyContent="start"
              sx={activePath === '/my-papers' ? activeButtonStyle : { ':hover': hoverStyle }}
              onClick={() => navigate('/my-papers')}
            >
              <Image src={MyPapers} alt="my-papers-icon" />
              <Heading as="h1" fontSize="12px" fontStyle="normal" fontWeight="350" color="#ffffff">
                My Papers
              </Heading>
            </Button>

            <Button
              gap="4px"
              paddingTop="10px"
              paddingBottom="10px"
              width="100%"
              variant="ghost"
              justifyContent="start"
              sx={activePath === '/my-subjects' ? activeButtonStyle : { ':hover': hoverStyle }}
              onClick={() => navigate('/my-subjects')}
            >
              <Image src={MySubjects} alt="my-subjects-icon" />
              <Heading as="h1" fontSize="12px" fontStyle="normal" fontWeight="400" color="#ffffff">
                My Subjects
              </Heading>
            </Button>

            <Box height="85px" />

            <Button
              gap="4px"
              paddingTop="10px"
              paddingBottom="10px"
              width="100%"
              variant="ghost"
              justifyContent="start"
              sx={activePath === '/settings' ? activeButtonStyle : { ':hover': hoverStyle }}
              onClick={() => navigate('/settings')}
            >
              <Image src={SettingsIcon} alt="settings-icon" />
              <Heading as="h1" fontSize="12px" fontStyle="normal" fontWeight="400" color="#ffffff">
                Settings
              </Heading>
            </Button>

            <Button
              gap="4px"
              paddingTop="10px"
              paddingBottom="10px"
              width="100%"
              variant="ghost"
              justifyContent="start"
              sx={{ ':hover': hoverStyle }}
              onClick={handleLogout}
            >
              <Image src={LogoutIcon} alt="logout-icon" />
              <Heading as="h1" fontSize="12px" fontStyle="normal" fontWeight="400" color="#ffffff">
                Logout
              </Heading>
            </Button>
          </VStack>
        </Flex>
      </Box>
    </>
  );
};

export default Sidebar;
