import React, { useState, useEffect, useRef  } from "react";
import { Box, Flex, HStack, VStack, Heading, Image, Button, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import StartSound from "../Images/StartSound.mp3";
import EndSound from "../Images/StartSound.mp3";
import BackArrow from "../Images/SolveHeaderArrowIcon.svg";
import PointBreak from "../Images/Circle.svg";

const SolveHeader = ({ subjectName, paperName, timeHour, timeMin, onTimeUp, setTestStarted   }) => {
  const navigate = useNavigate();
  const [testMode, setTestMode] = useState(false);
  const [timeLeft, setTimeLeft] = useState(timeHour * 3600 + timeMin * 60);
  const [showAlert, setShowAlert] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null); // For managing MediaRecorder
  const [videoStream, setVideoStream] = useState(null); // To hold the video stream
  const recordedChunks = useRef([]); // To store video data chunks
  const videoRef = useRef(null); // Reference for the video element

  const startWebcamRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: false, // Set to true if audio is needed
      });
      setVideoStream(stream);

      // Attach the stream to the video element
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      }

      const recorder = new MediaRecorder(stream);
      recorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          recordedChunks.current.push(event.data);
        }
      };
      recorder.start();
      setMediaRecorder(recorder);
    } catch (err) {
      console.error("Error accessing webcam:", err);
    }
  };

  const stopWebcamRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      mediaRecorder.onstop = () => {
        const blob = new Blob(recordedChunks.current, { type: "video/webm" });
        const videoURL = URL.createObjectURL(blob);

        // Save or upload the video
        console.log("Video URL:", videoURL);

        recordedChunks.current = []; // Clear the chunks after saving
      };
    }

    if (videoStream) {
      videoStream.getTracks().forEach((track) => track.stop()); // Stop the video stream
      setVideoStream(null);
    }

    // Stop the video preview
    if (videoRef.current) {
      videoRef.current.srcObject = null;
    }
  };

  useEffect(() => {
    return () => {
      stopWebcamRecording(); // Ensure recording stops if the component unmounts
    };
  }, []);


  const handleTestModeClick = () => {
    setTestMode(true);
    setTestStarted(true); // Start the test and hide submit button
    startWebcamRecording();
    new Audio(StartSound).play();
    document.documentElement.requestFullscreen(); // Enter full-screen mode
    window.history.pushState(null, null, window.location.href); // Lock back navigation
  };


  const handleCancelTestClick = (reason) => {
    setTestMode(false);
    setTestStarted(false);
    stopWebcamRecording();
    setTimeLeft(timeHour * 3600 + timeMin * 60); // Reset timer

    // Check if the document is in full-screen mode before exiting
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }

    if (reason === "tab-switch" || reason === "minimize") {
      setShowAlert(true);
    }
  };



  useEffect(() => {
    if (testMode) {
      const preventNavigation = (e) => {
        e.preventDefault();
        e.returnValue = ""; // Prevent reload
      };

      const preventBackNavigation = () => {
        window.history.pushState(null, null, window.location.href); // Re-lock history
      };

      window.addEventListener("beforeunload", preventNavigation);
      window.addEventListener("popstate", preventBackNavigation);

      return () => {
        window.removeEventListener("beforeunload", preventNavigation);
        window.removeEventListener("popstate", preventBackNavigation);
        stopWebcamRecording();
      };
    }
  }, [testMode]);

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timer);
    } else {
      onTimeUp(); // Call the onTimeUp function when time reaches zero
    }
  }, [timeLeft, onTimeUp]);

  useEffect(() => {
    const handleTabChange = () => {
      if (testMode && document.hidden) {
        handleCancelTestClick("tab-switch"); // Cancel test if user switches tabs
      }
    };

    document.addEventListener("visibilitychange", handleTabChange);

    return () => {
      document.removeEventListener("visibilitychange", handleTabChange);
    };
  }, [testMode]);

  useEffect(() => {
    const handleFullscreenChange = () => {
      if (testMode && !document.fullscreenElement) {
        handleCancelTestClick("minimize"); // Cancel test if user exits full-screen mode
      }
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, [testMode]);

  useEffect(() => {
    const handleResize = () => {
      if (testMode && window.outerWidth < 800 && window.outerHeight < 600) {
        // Cancel test only if window is minimized below a certain threshold (can be adjusted as needed)
        handleCancelTestClick("minimize");
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [testMode]);

  useEffect(() => {
    const disableShortcuts = (e) => {
      if (testMode) {
        const restrictedKeys = ["F5", "F12", "Tab", "Escape"];
        if (
          restrictedKeys.includes(e.key) ||
          ((e.ctrlKey || e.metaKey) && ["t", "w", "r", "n"].includes(e.key))
        ) {
          e.preventDefault();
        }
      }
    };

    window.addEventListener("keydown", disableShortcuts);

    return () => {
      window.removeEventListener("keydown", disableShortcuts);
    };
  }, [testMode]);

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(remainingSeconds).padStart(2, "0")}`;
  };

  return (
    <>
      <Box
        padding="27px 29px"
        alignItems="center"
        position="fixed"
        top="0"
        width="100%"
        zIndex="999"
        background="rgba(255, 255, 255, 255)"
        boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
      >
        <HStack gap="54px" justifyContent="space-between">
          <Flex
            gap="8px"
            alignItems="center"
            cursor={testMode ? "not-allowed" : "pointer"}
            onClick={!testMode ? () => navigate("/dashboard") : undefined}
          >
            <Image src={BackArrow} alt="arrow icon" />
            <Heading fontSize="14px" fontWeight="400" color="#021A04">
              to dashboard 
            </Heading>
          </Flex>
          <VStack gap="2px" alignItems="left">
            <Heading fontSize="18px" fontWeight="400" color="#021A04">
              {subjectName}
            </Heading>
            <Flex gap="4px">
              <Heading fontSize="14px" fontWeight="300" color="#021A04">
                {paperName}
              </Heading>
              <Image src={PointBreak} alt="circle-Icon" />
              <Heading fontSize="14px" fontWeight="300" color="#021A04">
                {timeHour} hour {timeMin} min
              </Heading>
            </Flex>
          </VStack>
          <Flex gap="8px">
            {testMode ? (
              <HStack align="left" spacing={4}>
                <Flex direction="column" align="start">
                  <Text fontSize="14px" fontWeight="400" color="#535953" maxWidth="400px">
                    You are in test mode. You can only submit questions. The test will auto-submit when time is over.
                  </Text>
                  <Flex
                    width="185px"
                    height="55px"
                    borderRadius="14px"
                    background="#070707"
                    alignItems="center"
                    justifyContent="center"
                    mt={4}
                  >
                    <Text fontSize="18px" fontWeight="400" color="#4BFF5C">
                      {formatTime(timeLeft)}
                    </Text>
                  </Flex>
                </Flex>
                <Button
                  display="flex"
                  padding="10px 24px"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="14px"
                  background="#070707"
                  color="#FBFAFA"
                  fontSize="14px"
                  fontWeight="400"
                  boxShadow="0px 0px 5.9px 0px rgba(0, 0, 0, 0.08)"
                  width="145px"
                  _hover={{ background: "white", color: "#021A04" }}
                  onClick={() => handleCancelTestClick()}
                >
                  Cancel Test
                </Button>
              </HStack>
            ) : (
              <Button
                display="flex"
                padding="10px 24px"
                justifyContent="center"
                alignItems="center"
                borderRadius="14px"
                background="#070707"
                color="#FBFAFA"
                fontSize="14px"
                fontWeight="400"
                boxShadow="0px 0px 5.9px 0px rgba(0, 0, 0, 0.08)"
                width="145px"
                _hover={{ background: "white", color: "#021A04" }}
                onClick={handleTestModeClick}
              >
                Test Mode
              </Button>
            )}
          </Flex>
        </HStack>
      </Box>

      {showAlert && (
        <Box
          display="inline-flex"
          height="243px"
          padding="46px 131px 119px 77px"
          flexDirection="column"
          alignItems="flex-start"
          gap="26px"
          borderRadius="14px"
          background="#070707"
          boxShadow="0px 0px 9.6px 0px rgba(0, 0, 0, 0.11)"
          position="fixed"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          zIndex="1000"
        >
          <Text color="#ffffff" fontSize="20px" fontWeight="500">
            Test Canceled
          </Text>
          <Text fontSize="14px" fontWeight="400" color="#ffffff">
            Your test was canceled because you minimized or resized the browser. Please restart if you wish to try again.
          </Text>
          <Button
            onClick={() => setShowAlert(false)}
            backgroundColor="#4BFF5C"
            color="#021A04"
            borderRadius="8px"
            padding="8px 16px"
          >
            OK
          </Button>
        </Box>
      )}
      {/* Video Preview Box */}
      {testMode && (
        <Box
          position="fixed"
          bottom="10px"
          left="10px"
          width="200px"
          height="150px"
          borderRadius="10px"
          overflow="hidden"
          boxShadow="0px 4px 6px rgba(0, 0, 0, 0.1)"
          zIndex="1000"
        >
          <video
            ref={videoRef}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "10px",
            }}
          />
        </Box>
      )}

    </>
  );
};

export default SolveHeader;
