import React from "react";
import { Box, Grid, Flex, Heading, Text, Image, Link } from "@chakra-ui/react";
import footerLogo from "./Logo.svg";
import twitterIcon from "./Twitter.svg";
import facebookIcon from "./facebook.svg";
import instagramIcon from "./instagram.svg";

const Footer = () => {
  const listItemStyle = {
    color: "#e7eae6",
    opacity: 0.6,
    fontSize: "sm",
    fontWeight: "normal",
  };

  return (
    <Box as="footer" bg="black" py={8} width="100%" marginTop="254px" paddingX={{ base: "20px", md: "45px" }}>
      <Box mx="auto">
        <Grid
          templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }} // Adjusted to 3 columns for large screens, stacked on mobile
          gap={8} // Reduced gap for mobile
          textAlign={{ base: "center", md: "left" }}
          paddingTop="54px"
        >
          <Flex
            flexDirection="column"
            alignItems={{ base: "center", md: "flex-start" }}
            color="white"
            fontSize="xl"
            fontWeight="medium"
            fontFamily="'Mona Sans', sans-serif"
          >
            <Heading as="h1" fontSize={{ base: "24px", md: "32px" }}>Stand</Heading>
            <Image src={footerLogo} alt="logo-image" mt={4} />
          </Flex>

          <Flex flexDirection="column" alignItems={{ base: "center", md: "flex-start" }} paddingTop="54px">
            <Heading as="h2" color="#d9d9d9" fontSize="lg" fontWeight="semibold">
              Links
            </Heading>
            <Flex flexDirection="column" gap={2} marginTop="14px"> {/* Adjusted layout */}
              <Link href="/" {...listItemStyle}>
                Home
              </Link>
              <Link href="/About" {...listItemStyle}>
                Product
              </Link>
              <Link href="/Menu" {...listItemStyle}>
                Features
              </Link>
              <Link href="/Reservation" {...listItemStyle}>
                About us
              </Link>
            </Flex>
          </Flex>

          <Flex flexDirection="column" alignItems={{ base: "center", md: "flex-start" }} paddingTop="54px">
            <Heading as="h2" color="#d9d9d9" fontSize="lg" fontWeight="semibold">
              Contact
            </Heading>
            <Flex flexDirection="column" gap={2} marginTop="14px"> {/* Adjusted layout */}
              <Link href="/phone" {...listItemStyle}>
                Phone
              </Link>
              <Link href="/Email" {...listItemStyle}>
                Email
              </Link>
              <Link href="/address" {...listItemStyle}>
                Address
              </Link>
            </Flex>
          </Flex>
        </Grid>

        <Flex
          mt={8}
          pt={8}
          flexDirection={{ base: "column", md: "row" }}
          justifyContent="space-between"
          alignItems="center"
          textAlign={{ base: "center", md: "left" }}
        >
          <Text color="#e7eae6" opacity={0.6} fontSize="sm">
            © 2024 All rights reserved
          </Text>
          <Flex mt={{ base: 4, md: 0 }} gap={4}>
            <Image src={twitterIcon} alt="Twitter-icon" boxSize={6} />
            <Image src={facebookIcon} alt="Facebook-icon" boxSize={6} />
            <Image src={instagramIcon} alt="Instagram-icon" boxSize={6} />
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};

export default Footer;