import React, { useState } from "react";
import { Flex, Box, Image, Text } from "@chakra-ui/react";
import PerformanceIcon from "../Images/PerformanceCardIcon.svg";

const PerformanceCardDashboard = () => {

  const [isHovered, setIsHovered] = useState(false);

  return (
    <Box
      width="245px"
      height="114px" // Slightly increased height for better layout balance
      display="flex"
      flexDirection="column"
      justifyContent="space-between" // Ensure both parts are spaced
      alignItems="center"
      gap="4px"
      flexShrink="0"
      
    >
      {/* Main solved papers box */}
      <Box
        display="flex"
        padding="14px 40px 16px 12px"
        alignItems="center"
        justifyContent="center" // Center the icon and text
        borderRadius="20px"
        width="100%" // Full width for balance
        onMouseEnter = {() => setIsHovered(true)}
        onMouseLeave= {() => setIsHovered(false)}
        background = {isHovered ? "transparent": "#070707"}
        border = {isHovered ? "1px solid #E7EAE8": "transparent"}

      >
        <Image
          src={PerformanceIcon}
          alt="Performance Icon"
          marginRight="12px"
          boxSize="40px" // Icon size
        />
        <Text
          
          fontSize="18px"
          fontWeight="400"
          lineHeight="normal"
          marginRight="8px"
          color = {isHovered ? "#021A04": "#F9FBF9"}
        >
          0
        </Text>
        <Text
          fontSize="14px"
          fontWeight="400"
          lineHeight="normal"
          color = {isHovered ? "#021A04": "#F9FBF9"}
        >
          Papers solved
        </Text>
      </Box>

      {/* Overall performance box */}
      <Box
        display="flex"
        flexDirection="column"
        gap="8px"
        alignItems="stretch" // Ensure the text and bar are aligned
        width="100%" // Take up full width for balance
        padding="0 10px" // Add padding for balance
      >
        <Flex justifyContent="space-between" alignItems="center" width="100%">
          <Text
            color="#021A04"
            fontSize="14px"
            fontWeight="300"
            lineHeight="normal"
          >
            Overall performance
          </Text>
          <Text
            color="#021A04"
            fontSize="14px"
            fontWeight="300"
            lineHeight="normal"
          >
            0%
          </Text>
        </Flex>

        {/* Progress bar */}
        <Box
          width="100%"
          height="10px" // Slightly increased height
          backgroundColor="#F1F1F1"
          borderRadius="5px"
          overflow="hidden"
          position="relative"
        >
          <Box
            width="0%" // Adjust the percentage width as per progress
            height="100%"
            backgroundColor="#021A04"
            borderRadius="5px"
            position="absolute"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default PerformanceCardDashboard;
