import React, { useEffect, useState } from "react";
import { Box, Button, HStack, VStack, Text, Image, Flex, Heading, Spinner, SimpleGrid } from "@chakra-ui/react";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from "@chakra-ui/react";
import CardPastPaperIcon from "../Images/PastPaperIconCard-icon.svg";
import CardArrowIcon from "../Images/ArrowPastPaperIconCard.svg";
import HoverCardIcon from "../Images/HoverCardIcon.svg";
import HoverArrowIcon from "../Images/HoverArrowIcon.svg";
import SolveIcon from "../Images/Solve-icon.svg";
import DownloadIcon from "../Images/Download-icon.svg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BASE_API_URL } from './config';

const PastPaperBoard = () => {
  const [boards, setBoards] = useState([]);
  const [qualifications, setQualifications] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [pastPapers, setPastPapers] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedBoard, setSelectedBoard] = useState(null);
  const [selectedQualification, setSelectedQualification] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedPastPaper, setSelectedPastPaper] = useState(null);
  const [showPastPapers, setShowPastPapers] = useState(false);
  const [activeButton, setActiveButton] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [showInProgressModal, setShowInProgressModal] = useState(false);
  const [markingSchemes, setMarkingSchemes] = useState({});

  const userGuid = localStorage.getItem('userGuid');
  const navigate = useNavigate();
  const [isPastPaperHovered, setIsPastPaperHovered] = useState(false);
  const [isTopicalQuestionsHovered, setIsTopicalQuestionsHovered] = useState(false);
  const [activeBoard, setActiveBoard] = useState(null);

  useEffect(() => {
    if (userGuid) {
      setLoading(true);
      axios
        .get(`${BASE_API_URL}/api/v1/exam-boards/`)
        .then((response) => {
          setBoards(response.data);
          setError(null);
        })
        .catch((error) => {
          setError("There was an error fetching the exam boards.");
          console.error(error);
        })
        .finally(() => setLoading(false));
    } else {
      setError("User GUID is missing. Please log in again.");
    }
  }, [userGuid]);

  const boardQualification = (guid) => {
    setLoading(true);
    axios
      .get(`${BASE_API_URL}/api/v1/exam-qualifications/by-exam-board-guid/${guid}`)
      .then((response) => {
        setQualifications(response.data);
        if (response.data.length === 0) {
          setError("No qualifications available for this board yet.");
        } else {
          setError(null); 
        }
      })
      .catch((error) => {
        console.error(error);
        setError("There was an error fetching the qualifications.");
      })
      .finally(() => setLoading(false));
  };

  const qualificationSubjects = (guid) => {
    setLoading(true);
    axios
      .get(`${BASE_API_URL}/api/v1/subjects/by-exam-qualification-guid/${guid}`)
      .then((response) => {
        setSubjects(response.data);
        setError(null);
      })
      .catch((error) => {
        setError("There was an error fetching the subjects.");
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  const subjectPastPaper = (guid) => {
    setLoading(true);
    axios
      .get(`${BASE_API_URL}/api/v1/past-papers/by-subject-guid/${guid}`)
      .then((response) => {
        setPastPapers(response.data);
        setError(null);
        fetchMarkingSchemes(response.data); // Fetch marking schemes when past papers are loaded
      })
      .catch((error) => {
        setError("There was an error fetching the past papers.");
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  const fetchMarkingSchemes = (papers) => {
    papers.forEach((paper) => {
      axios
        .get(`${BASE_API_URL}/api/v1/marking-schemes`, { params: { past_paper_guid: paper.guid } })
        .then((response) => {
          setMarkingSchemes((prevSchemes) => ({
            ...prevSchemes,
            [paper.guid]: response.data.file_url,
          }));
        })
        .catch((error) => {
          console.error("Error fetching marking scheme", error);
        });
    });
  };

  const handleSelectBoard = (board) => {
    setSelectedBoard(board);
    setQualifications([]); 
    setSubjects([]); 
    setPastPapers([]); 
    setSelectedQualification(null); 
    setSelectedSubject(null); 
    boardQualification(board.guid);
  };

  const handleSelectQualification = (qualification) => {
    setSelectedQualification(qualification);
    qualificationSubjects(qualification.guid);
  };

  const handleSelectSubject = (subject) => {
    setSelectedSubject(subject);
    setPastPapers([]);
    subjectPastPaper(subject.guid);
  };

  const handleSolveClick = async (paperGuid) => {
    try {
      const paper_attempt_response = await axios.post(
        `${BASE_API_URL}/api/v1/user-paper-attempts/`,
        {
          completion_status: "INCOMPLETE",
          start_date_time: new Date().toISOString(),
          updated_date_time: new Date().toISOString(),
          total_scored_marks: 0,
          user_guid: userGuid,
          past_paper_guid: paperGuid,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );
  
      const user_paper_attempt_guid = paper_attempt_response.data.guid;
  
      const paperDetailResponse = await axios.get(
        `${BASE_API_URL}/api/v1/user-paper-attempts/detail/${user_paper_attempt_guid}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );
  
      const { subject_name, paper_name, paper_code, time_hour, time_min } = paperDetailResponse.data;
      const formattedPaperName = `Paper ${paper_name.split(' ')[1]}${paper_code.trim().slice(-1)} ${paper_name.split(' ').slice(2).join(' ')}`;
  
      const questionsResponse = await axios.get(
        `${BASE_API_URL}/api/v1/user-paper-attempts/questions-with-attempt/${user_paper_attempt_guid}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );
  
      setQuestions(questionsResponse.data);
  
      navigate('/solve-paper', {
        state: {
          questions: questionsResponse.data,
          paper_Guid: user_paper_attempt_guid,
          subject_name,
          formattedPaperName,
          time_hour,
          time_min
        },
      });
  
    } catch (error) {
      if (error.response && error.response.data.detail === "Existing attempt already in-progress") {
        setShowInProgressModal(true);
      } else {
        console.error("Error creating attempt:", error);
        setError("There was an error trying to access this paper. Please try again");
      }
    } finally {
      setLoading(false);
    }
  };
  
  const handleDownloadClick = (fileUrl) => {
    window.open(fileUrl, '_blank');
  };

  const handleDownloadMarkingScheme = (paperGuid) => {
    window.open(markingSchemes[paperGuid], '_blank');
  };

  const handleSelectedPastPaper = () => {
    setShowPastPapers(true); // This will handle the showing of the past papers when clicked
  };

  const handleBoardClick = (board) => {
    setActiveBoard(board);
  };

  const groupPapersByMonthYear = (papers) => {
    return papers.reduce((groups, paper) => {
      const key = `${paper.exam_series_month} ${paper.exam_series_year}`;
      if (!groups[key]) {
        groups[key] = [];
      }
      groups[key].push(paper);
      return groups;
    }, {});
  };

  const groupedPapers = groupPapersByMonthYear(pastPapers);

  return (
    <Box spacing={18}>
      {loading && <Spinner size="xl" color="#4BFF5C" />}
      {/* Board Selection */}
      <Box position="relative" width="258px" borderBottom="3px solid #E5ECE6" mb={8}>
        <HStack spacing={2} justify="space-between">
          {boards.map((board) => (
            <Button
              key={board.guid}
              variant="unstyled"
              onClick={() => {
                handleSelectBoard(board);
                setActiveBoard(board);
              }}
              fontWeight="400"
              fontSize="14px"
              color={activeBoard && activeBoard.guid === board.guid ? "#021A04" : "#535953"}
              _hover={{ color: "#021A04" }}
              position="relative"
            >
              {board.code}
              <Box
                position="absolute"
                bottom="-2px"
                left="0"
                right="0"
                height="2px"
                bg={activeBoard && activeBoard.guid === board.guid ? "#4BFF5C" : "transparent"}
                transition="background-color 0.3s ease"
              />
            </Button>
          ))}
        </HStack>
      </Box>

      {/* Error Message */}
      {error && (
        <Text color="#535953" fontSize="14px" fontWeight="300" mb={4}>
          {error}
        </Text>
      )}

      {/* Content */}
      {!loading && (
        <>
          {selectedBoard && (
            <HStack spacing={6} mb={8}>
              {qualifications.map((qualification) => (
                <Button
                  key={qualification.code}
                  padding="10px"
                  color={selectedQualification && selectedQualification.code === qualification.code ? "#070707" : "#070707"}
                  fontSize="12px"
                  fontWeight="500"
                  bg={selectedQualification && selectedQualification.code === qualification.code ? "#F1F1F1" : "linear-gradient(240deg, #0FFD26 12.06%, #7BFC87 85.61%)"}
                  variant="unstyled"
                  alignItems="center"
                  textAlign="center"
                  whiteSpace="nowrap"
                  width="auto"
                  onClick={() => handleSelectQualification(qualification)}
                  _hover={{
                    bg: "#F1F1F1",
                    color: "#021A04",
                  }}
                >
                  {qualification.code}
                </Button>
              ))}
            </HStack>
          )}

          {selectedQualification && (
            <HStack spacing={6} mb={14}>
              {subjects.map((subject) => (
                <Button
                  key={subject.code}
                  padding="10px"
                  color={selectedSubject && selectedSubject.code === subject.code ? "#021A04" : "#FFFFFF"}
                  fontSize="12px"
                  fontWeight="500"
                  bg={selectedSubject && selectedSubject.code === subject.code ? "#F1F1F1" : "#021A04"}
                  borderRadius="100px"
                  variant="unstyled"
                  alignItems="center"
                  textAlign="center"
                  whiteSpace="nowrap"
                  width="auto"
                  onClick={() => handleSelectSubject(subject)}
                  _hover={{
                    bg: "#F1F1F1",
                    boxShadow: "0px 2px 6.7px 0px rgba(0, 0, 0, 0.14)",
                    color: "#021A04"
                  }}
                >
                  {subject.name}
                </Button>
              ))}
            </HStack>
          )}

          {selectedSubject && (
            <>
              {/* Display Cards */}
              <HStack spacing={4} mt={4}>
                {/* Past Papers Card */}
                <Button
                  paddingTop="24px"
                  paddingLeft="24px"
                  paddingBottom="24px"
                  bg={selectedCard === "PastPaper" ? "transparent" : "linear-gradient(240deg, #0FFD26 12.06%, #7BFC87 85.61%)"}
                  border="2px solid"
                  borderColor={selectedCard === "PastPaper" ? "#E7EAE8" : "transparent"}
                  borderRadius="18px"
                  variant="unstyled"
                  height="247px"
                  textAlign="left"
                  width={{ base: "100%", md: "45%" }}
                  _hover={{
                    border: "2px solid #E7EAE8",
                    background: "transparent",
                  }}
                  _active={{
                    border: "2px solid #E7EAE8",
                    background: "transparent",
                  }}
                  onMouseEnter={() => setIsPastPaperHovered(true)}
                  onMouseLeave={() => setIsPastPaperHovered(false)}
                  onClick={() => {
                    if (selectedCard === "PastPaper") {
                      setSelectedCard(null);
                      setShowPastPapers(false);
                    } else {
                      handleSelectedPastPaper();
                      setSelectedCard("PastPaper");
                    }
                  }}
                >
                  <Flex direction="column" flex="1" justify="space-between">
                    <Box>
                      <Image
                        src={isPastPaperHovered || selectedCard === "PastPaper" ? HoverCardIcon : CardPastPaperIcon}
                        alt="Past Papers Icon"
                        marginBottom="54px"
                      />
                      <Heading color="#021A04" fontSize="20px" fontWeight="400" fontStyle="normal" mt={2}>
                        Past Papers
                      </Heading>
                      <Text
                        color="#282828"
                        fontSize="14px"
                        fontWeight="300"
                        fontStyle="normal"
                        width="80%"
                        whiteSpace="normal"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        mt={2}
                      >
                        You can choose to download papers or solve them in the dashboard and get help with instant marking and feedback to better your work gradually.
                      </Text>
                    </Box>
                    <Box position="absolute" bottom="8px" right="8px">
                      <Image
                        src={isPastPaperHovered || selectedCard === "PastPaper" ? HoverArrowIcon : CardArrowIcon}
                        alt="Card Arrow Icon"
                      />
                    </Box>
                  </Flex>
                </Button>

                {/* Topical Questions Card */}
                <Button
                  paddingTop="24px"
                  paddingLeft="24px"
                  paddingBottom="24px"
                  bg={selectedCard === "TopicalQuestions" ? "transparent" : "linear-gradient(240deg, #0FFD26 12.06%, #7BFC87 85.61%)"}
                  border="2px solid"
                  borderColor={selectedCard === "TopicalQuestions" ? "#E7EAE8" : "transparent"}
                  borderRadius="18px"
                  variant="unstyled"
                  height="247px"
                  textAlign="left"
                  width={{ base: "100%", md: "45%" }}
                  _hover={{
                    border: "2px solid #E7EAE8",
                    background: "transparent",
                  }}
                  _active={{
                    border: "2px solid #E7EAE8",
                    background: "transparent",
                  }}
                  onMouseEnter={() => setIsTopicalQuestionsHovered(true)}
                  onMouseLeave={() => setIsTopicalQuestionsHovered(false)}
                  onClick={() => {
                    if (selectedCard === "TopicalQuestions") {
                      setSelectedCard(null);
                    } else {
                      setSelectedCard("TopicalQuestions");
                    }
                  }}
                >
                  <Flex direction="column" flex="1" justify="space-between">
                    <Box>
                      <Image
                        src={isTopicalQuestionsHovered || selectedCard === "TopicalQuestions" ? HoverCardIcon : CardPastPaperIcon}
                        alt="Topical Questions Icon"
                        marginBottom="54px"
                      />
                      <Heading color="#021A04" fontSize="20px" fontWeight="400" fontStyle="normal" mt={2}>
                        Topical Questions
                      </Heading>
                      <Text
                        color="#282828"
                        fontSize="14px"
                        fontWeight="300"
                        fontStyle="normal"
                        width="80%"
                        whiteSpace="normal"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        mt={2}
                      >
                        You can choose to download papers or solve them in the dashboard and get help with instant marking and feedback to better your work gradually.
                      </Text>
                    </Box>
                    <Box position="absolute" bottom="8px" right="8px">
                      <Image
                        src={isTopicalQuestionsHovered || selectedCard === "TopicalQuestions" ? HoverArrowIcon : CardArrowIcon}
                        alt="Card Arrow Icon"
                      />
                    </Box>
                  </Flex>
                </Button>
              </HStack>

              {/* Display Grouped Past Papers if Past Papers Card is Selected */}
              {showPastPapers && selectedCard === "PastPaper" && (
                <>
                  <Heading as="h3" color="#021A04" fontSize="20px" fontWeight="500" mt={8} mb={8}>
                    Past Year Papers
                  </Heading>

                  {/* Headings for Exam Papers and Mark Scheme */}
                  <SimpleGrid columns={{ base: 1, md: 2 }} spacing={2} mb={4}>
                    <Heading as="h4" color="#021A04" fontSize="16px" fontWeight="500" textAlign = "left">
                      Exam Papers
                    </Heading>
                    <Heading as="h4" color="#021A04" fontSize="16px" fontWeight="500" textAlign = "left">
                      Mark Schemes
                    </Heading>
                  </SimpleGrid>

                  {Object.keys(groupedPapers).map((groupKey) => (
                    <SimpleGrid
                      key={groupKey}
                      columns={{ base: 1, md: 2 }}
                      spacing={4}
                      alignItems="flex-start"
                      mb={8}
                    >
                      {/* Exam Papers Box */}
                      <Box
                        bg="#FFF"
                        borderRadius="14px"
                        boxShadow="0px 0px 9.6px 0px rgba(0, 0, 0, 0.1111)"
                        p="16px"
                        width = "423px"
                      >
                        <Heading as="h5" color="#021A04" fontSize="16px" fontWeight="500" mb={4}>
                          {groupKey}
                        </Heading>
                        <VStack spacing={8} align="stretch">
                          {groupedPapers[groupKey].map((paper, index) => (
                            <Box key={index}>
                              <Text color="#021A04" fontSize="14px" fontWeight="400" mb={2}>
                                {`Paper ${paper.name.split(' ')[1]}${paper.code.trim().slice(-1)} ${paper.name
                                  .split(' ')
                                  .slice(2)
                                  .join(' ')}`}
                              </Text>
                              <HStack spacing={4}>
                                <Button
                                  display="flex"
                                  variant="link"
                                  color="#535953"
                                  fontSize="14px"
                                  fontWeight="300"
                                  onClick={() => handleSolveClick(paper.guid)}
                                >
                                  Solve <Image src={SolveIcon} alt="Solve Icon" ml="4px" />
                                </Button>
                                <Button
                                  display="flex"
                                  variant="link"
                                  color="#535953"
                                  fontSize="14px"
                                  fontWeight="300"
                                  onClick={() => handleDownloadClick(paper.file_url)}
                                >
                                  Download <Image src={DownloadIcon} alt="Download Icon" ml="4px" />
                                </Button>
                              </HStack>
                            </Box>
                          ))}
                        </VStack>
                      </Box>

                      {/* Mark Scheme Box */}
                      
                      <Box
                        bg="#FFF"
                        borderRadius="14px"
                        boxShadow="0px 0px 9.6px 0px rgba(0, 0, 0, 0.1111)"
                        p="16px"
                        width = "423px"
                      >
                        <Heading as="h5" color="#021A04" fontSize="16px" fontWeight="500" mb={4}>
                          {groupKey}
                        </Heading>
                        <VStack spacing={8} align="stretch">
                          {groupedPapers[groupKey].map((paper, index) => (
                            <Box key={index}>
                              <Text color="#021A04" fontSize="14px" fontWeight="400" mb={2}>
                                {`Paper ${paper.name.split(' ')[1]}${paper.code.trim().slice(-1)} `}
                              </Text>
                              <Button
                                display="flex"
                                variant="link"
                                color="#535953"
                                fontSize="14px"
                                fontWeight="300"
                                onClick={() => handleDownloadMarkingScheme(paper.guid)}
                              >
                                Download <Image src={DownloadIcon} alt="Download Icon" ml="4px" />
                              </Button>
                            </Box>
                          ))}
                        </VStack>
                      </Box>
                    </SimpleGrid>
                  ))}
                </>
              )}


            </>
          )}

        </>
      )}

      {/* Modal for in-progress attempt */}
      <Modal isOpen={showInProgressModal} onClose={() => setShowInProgressModal(false)}>
        <ModalOverlay />
        <ModalContent display="inline-flex" gap="26px" borderRadius="14px" background="#070707">
          <ModalHeader color="#ffffff" fontSize="20px" fontWeight="500">
            Attempt in Progress
          </ModalHeader>
          <ModalCloseButton color="#021A04" />
          <ModalBody>
            <Text fontSize="14px" fontWeight="400" color="#ffffff">
              You have already started solving this paper. You can access it from "My Papers" or continue from where you stopped.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              backgroundColor="#4BFF5C"
              color="#021A04"
              borderRadius="14px"
              padding="8px 8px"
              fontSize="14px"
              fontWeight="400"
              onClick={() => {
                setShowInProgressModal(false);
                navigate("/my-papers");
              }}
            >
              Access paper
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default PastPaperBoard;
