import React, { useState, useRef, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
} from '@chakra-ui/react';

const TermsModal = ({ isOpen, onClose, onAgree }) => {
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);


  useEffect(() => {
    if (isOpen) {
      setIsScrolledToBottom(false);
    }
  }, [isOpen]);

  const handleScroll = (e) => {
    const target = e.target;
    const atBottom = target.scrollHeight - target.scrollTop <= target.clientHeight + 5; // Add 5px tolerance
    setIsScrolledToBottom(atBottom);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent bg="#FFFFFF" color="#021A04" borderRadius="14px">
        <ModalHeader fontSize="18px" fontWeight="500" textAlign="center">
          Terms and Conditions
        </ModalHeader>
          <ModalBody maxHeight="300px" overflowY="auto" padding="20px" onScroll={handleScroll}>
            <Text fontSize="14px" fontWeight="400" lineHeight="1.6">
              <Text as="span" fontWeight="bold">Effective Date:</Text> [Insert Date]
            </Text>

            <Text mt={4} fontSize="14px" fontWeight="400" lineHeight="1.6">
              <Text as="span" fontWeight="bold">1. Introduction</Text>
              <br />
              Welcome to [Your Business Name] (“we”, “us”, “our”). These Terms and Conditions (“Terms”) govern your use of our SaaS application located at [Your Website URL] (“the Service”). By accessing or using the Service, you agree to comply with and be bound by these Terms. If you do not agree with these Terms, you must not use the Service.
            </Text>

            <Text mt={4} fontSize="14px" fontWeight="400" lineHeight="1.6">
              <Text as="span" fontWeight="bold">2. Use of the Service</Text>
              <br />
              You may use the Service for lawful purposes only. You are responsible for ensuring that your use of the Service complies with all applicable laws and regulations in your jurisdiction.
            </Text>

            <Text mt={4} fontSize="14px" fontWeight="400" lineHeight="1.6">
              <Text as="span" fontWeight="bold">3. Account Registration</Text>
              <br />
              To use certain features of the Service, you must register an account. You agree to provide accurate, current, and complete information during the registration process and to update such information as needed. You are responsible for maintaining the confidentiality of your account credentials and for any activities that occur under your account. Notify us immediately of any unauthorized use or security breach.
            </Text>

            <Text mt={4} fontSize="14px" fontWeight="400" lineHeight="1.6">
              <Text as="span" fontWeight="bold">4. Subscription and Payment</Text>
              <br />
              The Service is offered on a subscription basis with recurring billing. By subscribing, you agree to the following:
              <br />
              <Text as="span" fontWeight="bold">Subscription Fees:</Text> You agree to pay the subscription fees as outlined on our pricing page at [Pricing URL]. Fees may be charged on a monthly or annual basis depending on the plan you choose.
              <br />
              <Text as="span" fontWeight="bold">Automatic Renewal:</Text> Your subscription will automatically renew unless you cancel it before the renewal date. Renewal fees will be charged at the then-current rates.
              <br />
              <Text as="span" fontWeight="bold">Cancellation:</Text> You can cancel your subscription at any time by contacting us at [Email Address]. Upon cancellation, access to the Service will be terminated at the end of the current billing period.
            </Text>

            <Text mt={4} fontSize="14px" fontWeight="400" lineHeight="1.6">
              <Text as="span" fontWeight="bold">5. Refund Policy</Text>
              <br />
              Refunds are not offered for partial use of the Service, except where required by Australian Consumer Law. If you believe you are entitled to a refund under Australian law, please contact us at [Email Address].
            </Text>

            <Text mt={4} fontSize="14px" fontWeight="400" lineHeight="1.6">
              <Text as="span" fontWeight="bold">6. Intellectual Property</Text>
              <br />
              All content and materials provided by us, including but not limited to software, logos, and text, are the intellectual property of [Your Business Name]. You may not reproduce, distribute, or create derivative works from our content without express permission.
            </Text>

            <Text mt={4} fontSize="14px" fontWeight="400" lineHeight="1.6">
              <Text as="span" fontWeight="bold">7. User Data and Privacy</Text>
              <br />
              We collect and use personal data as described in our Privacy Policy, which is incorporated by reference into these Terms. By using the Service, you agree to the collection, use, and storage of your information in accordance with our Privacy Policy [Insert Privacy Policy URL].
            </Text>

            <Text mt={4} fontSize="14px" fontWeight="400" lineHeight="1.6">
              <Text as="span" fontWeight="bold">8. Limitation of Liability</Text>
              <br />
              To the fullest extent permitted by law, [Your Business Name] is not liable for any indirect, incidental, special, consequential, or punitive damages arising out of your use of the Service, including but not limited to loss of profits, data, or goodwill.
            </Text>

            <Text mt={4} fontSize="14px" fontWeight="400" lineHeight="1.6">
              <Text as="span" fontWeight="bold">9. Disclaimer of Warranties</Text>
              <br />
              The Service is provided on an “as is” and “as available” basis. We do not guarantee that the Service will be uninterrupted, error-free, or free from viruses or other harmful components.
            </Text>

            <Text mt={4} fontSize="14px" fontWeight="400" lineHeight="1.6">
              <Text as="span" fontWeight="bold">10. Changes to These Terms</Text>
              <br />
              We may update these Terms from time to time. When we do, we will notify you by email or through a prominent notice on our website. Your continued use of the Service after changes to the Terms indicates your acceptance of those changes.
            </Text>

            <Text mt={4} fontSize="14px" fontWeight="400" lineHeight="1.6">
              <Text as="span" fontWeight="bold">11. Governing Law</Text>
              <br />
              These Terms are governed by and construed in accordance with the laws of Australia. Any disputes arising under these Terms will be subject to the exclusive jurisdiction of the courts of [Your State/Territory], Australia.
            </Text>

            <Text mt={4} fontSize="14px" fontWeight="400" lineHeight="1.6" >
              <Text as="span" fontWeight="bold">12. Contact Information</Text>
              <br />
              If you have any questions about these Terms, please contact us at:
              <br />
              [Your Business Name]
              <br />
              Email: [Your Email Address]
              <br />
              Phone: [Your Phone Number]
            </Text>
          </ModalBody>
        <ModalFooter justifyContent="center">
          <Button
            onClick={() => {
              onAgree();
              onClose();
            }}
            isDisabled={!isScrolledToBottom}
            sx={{
              padding: "10px 24px",
              background: isScrolledToBottom ? "#4BFF5C" : "#B3B3B3",
              color: "#08250B",
              borderRadius: "14px",
              fontFamily: "Mona Sans",
              fontSize: "14px",
              fontWeight: "400",
              lineHeight: "normal",
              boxShadow: "0px 0px 5.9px rgba(0, 0, 0, 0.08)",
              width: "25%",
              cursor: isScrolledToBottom ? "pointer" : "not-allowed",
              _hover: isScrolledToBottom ? { background: "#4BFF5C" } : {},
              transition: "background 0.3s, color 0.3s",
            }}

          >
            I Agree
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TermsModal;
