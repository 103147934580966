import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import PastPaperBoard from './PastPaperBoard';

const Home = () => {
  

  return (
    <Flex>
  
        <Box >
          <PastPaperBoard />
        </Box>
  
    </Flex>
  );
};

export default Home;
