import React, { useState } from "react";
import { Box, IconButton, Modal, ModalOverlay, ModalContent, ModalCloseButton } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { Rnd } from "react-rnd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

// Helper function to strip HTML tags
const stripHtmlTags = (html) => {
  const doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent || "";
};

const TextBox = ({ annotation, handleContentChange, handleDelete, updateAnnotationPosition }) => {
  const [editing, setEditing] = useState(false);
  const [editingContent, setEditingContent] = useState(annotation.content);

  const saveContent = () => {
    const plainTextContent = stripHtmlTags(editingContent); // Remove all HTML tags
    handleContentChange(plainTextContent, annotation.id);
    setEditing(false);
  };

  return (
    <>
      <Rnd
        default={{
          x: annotation.x,
          y: annotation.y,
          width: 200,
          height: 100,
        }}
        bounds="parent"
        enableResizing={{
          bottom: true,
          right: true,
          bottomRight: true,
        }}
        onDragStop={(e, d) => updateAnnotationPosition({ x: d.x, y: d.y }, annotation.id)}
        style={{ zIndex: 10 }}
      >
        <Box
          background="white"
          border="1px solid black"
          borderRadius="4px"
          position="relative"
          width="100%"
          height="100%"
          cursor="pointer"
          onDoubleClick={() => setEditing(true)}
        >
          <IconButton
            icon={<CloseIcon />}
            size="xs"
            color="white"
            background="black"
            borderRadius="50%"
            position="absolute"
            top="5px"
            right="5px"
            onClick={() => handleDelete(annotation.id)}
            _hover={{ background: "#4BFF5C" }}
          />

          {editing ? (
            <Modal isOpen={editing} onClose={() => setEditing(false)}>
              <ModalOverlay />
              <ModalContent>
                <ModalCloseButton onClick={() => setEditing(false)} />
                <Box padding={4}>
                  <ReactQuill
                    value={editingContent}
                    onChange={setEditingContent}
                    theme="snow"
                    modules={{
                      toolbar: false,
                      clipboard: { matchVisual: false }, // Prevents styled HTML paste
                    }}
                    formats={[]} // Minimal formatting options
                    style={{ minHeight: "150px" }}
                  />
                  <Box mt={4} textAlign="right">
                    <button onClick={saveContent}>Save</button>
                  </Box>
                </Box>
              </ModalContent>
            </Modal>
          ) : (
            <Box display="flex" alignItems="center" justifyContent="center" height="100%" padding="4px" fontSize="16px" color="black">
              {annotation.content || "Double-click to edit"}
            </Box>
          )}
        </Box>
      </Rnd>
    </>
  );
};

export default TextBox;
