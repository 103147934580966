import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  FormControl,
  FormLabel,
  Input,
  Button,
  Link as ChakraLink,
  Image,
  VStack,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import ButtonIcon from "../Images/ButtonIcon.svg";
import GoogleIcon from "../Images/Google-icon.svg";
import LoginPageGraphic from "../Images/LoginPageGraphic.svg";
import logo from "../Images/LOGO1.svg";
import line from "../Images/Line.svg";
import axios from 'axios';
import LoadingOverlay from './LoadingOverlay';
import { BASE_API_URL } from './config';

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const isAuthenticated = !!localStorage.getItem('accessToken');

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/home'); // Redirect if already logged in
    }
  }, [isAuthenticated, navigate]);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      rememberMe: false,
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Email is required"),
      password: Yup.string()
        .required("Password is required")
        .min(8, "Password must be at least 8 characters long"),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        const formData = new FormData();
        formData.append('username', values.email);
        formData.append('password', values.password);

        // Step 1: Log in and retrieve access token
        const loginResponse = await axios.post(`${BASE_API_URL}/api/v1/auth/sign-in`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        const { access_token, token_type } = loginResponse.data;

        // Step 2: Store access token and token type in localStorage
        localStorage.setItem('accessToken', access_token);
        localStorage.setItem('tokenType', token_type);

        // Step 3: Retrieve user details using the access token
        const userResponse = await axios.get(`${BASE_API_URL}/api/v1/users/own`, {
          headers: {
            Authorization: `${token_type} ${access_token}`, // Use the token_type and access_token
          },
        });

        const userDetails = userResponse.data;
        localStorage.setItem('userGuid', userDetails.guid);
        localStorage.setItem('subscriptionPlanGuid', userDetails.active_subscription_plan_guid);

        const firstLetter = userDetails.first_name.charAt(0).toUpperCase();
        localStorage.setItem('firstLetter', firstLetter);

        // Step 4: Check if there's a plan in sessionStorage (for payment flow)
        const selectedPlan = sessionStorage.getItem('selectedPlan');

        if (selectedPlan) {
          // Step 5: Proceed with payment by calling the checkout session API
          const subscriptionPlanGuid = userDetails.active_subscription_plan_guid; // Assuming subject_guid is userGuid
          const stripePriceId = "price_1QAxtACNup0MY3lGGNHg4nbg"; // You can map this based on selectedPlan
          const renewalType = "MONTHLY"; // Use correct renewal type (adjust if necessary)

          const checkoutResponse = await axios.post(
            `${BASE_API_URL}/api/v1/user-subscription-plans/create-checkout-session`,
            {
              subscription_plan_guid: subscriptionPlanGuid,
              stripe_price_id: stripePriceId,
              renewal_type: renewalType,
            },
            {
              headers: {
                Authorization: `${token_type} ${access_token}`, // Use the token_type and access_token
              },
            }
          );

          const { checkout_url } = checkoutResponse.data;

          // Redirect to the Stripe checkout URL
          window.location.href = checkout_url;
        } else {
          // If no payment plan is saved, redirect to home or dashboard
          navigate('/home');
        }
      } catch (error) {
        console.error("Login error:", error);
        setLoginError("Invalid email or password. Please try again.");
      } finally {
        setIsLoading(false); // Hide loading screen
      }
    },
  });

  const loginButtonStyle = {
    display: "flex",
    padding: "10px 24px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "14px",
    background: "#4BFF5C",
    color: "#08250B",
    fontFamily: "Mona Sans",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "normal",
    boxShadow: "0px 0px 5.9px 0px rgba(0, 0, 0, 0.08)",
    width: "100%",
    cursor: "pointer",
    transition: "background 0.3s, color 0.3s",
    _hover: {
      background: "transparent",
      color: "#021A04",
    },
  };

  const googleButtonStyle = {
    display: "flex",
    padding: "10px 34px",
    justifyContent: "center",
    alignItems: "center",
    gap: "4px",
    alignSelf: "stretch",
    borderRadius: "14px",
    background: "#021A04",
    color: "#E7EAE8",
    fontFamily: "Mona Sans",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "normal",
    boxShadow: "0px 0px 5.9px 0px rgba(0, 0, 0, 0.09)",
    width: "100%",
    cursor: "pointer",
    transition: "background 0.3s, color 0.3s",
    _hover: {
      background: "transparent",
      color: "#021A04",
    },
  };

  return (
    <>
      {isLoading && <LoadingOverlay />}
      <Flex height="100vh" direction={{ base: "column", md: "row" }}>
        <Box flex="1" display="flex" justifyContent="center" alignItems="center" p={{ base: "20px", md: "0" }}>
          <Box width={{ base: "100%", md: "429px" }} textAlign={{ base: "center", md: "left" }} p={{ base: "20px", md: "0" }}>
            <Flex mb="45px" justifyContent={{ base: "center", md: "left" }}>
              <Image src={logo} alt="logo-image" width={{ base: "120px", md: "125px" }} />
            </Flex>
            <Heading mb="4px" color="#021A04" fontSize="18px" fontWeight="500">
              Welcome!
            </Heading>
            <Text width="100%" color="#B3B3B3" fontSize="14px" fontWeight="400" mb={8}>
              Login to access unlimited exams for your chosen subjects and solve as well as get marked for free.
            </Text>

            <form onSubmit={formik.handleSubmit}>
              <VStack alignItems="left" spacing={8}>
                <FormControl isInvalid={formik.touched.email && formik.errors.email}>
                  <FormLabel color="#021A04" fontSize="14px" fontWeight="400">Email</FormLabel>
                  <Input
                    type="email"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    sx={{
                      width: "100%",
                      height: "45px",
                      borderRadius: "14px",
                      border: "1px solid #E7EAE8",
                      boxShadow: "0px 0px 5.9px 0px rgba(0, 0, 0, 0.05)",
                    }}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <Text color="red.400" fontSize="sm">{formik.errors.email}</Text>
                  )}
                </FormControl>

                <FormControl isInvalid={formik.touched.password && formik.errors.password}>
                  <FormLabel color="#021A04" fontSize="14px" fontWeight="400">Password</FormLabel>
                  <InputGroup>
                    <Input
                      type={showPassword ? 'text' : 'password'}
                      name="password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                      sx={{
                        width: "100%",
                        height: "45px",
                        borderRadius: "14px",
                        border: "1px solid #E7EAE8",
                        boxShadow: "0px 0px 5.9px 0px rgba(0, 0, 0, 0.05)",
                      }}
                    />
                    <InputRightElement width="4.5rem">
                      <Button
                        h="1.75rem"
                        size="sm"
                        onClick={togglePasswordVisibility}
                        sx={{
                          bg: "none",
                          _hover: { bg: "none", boxShadow: "none" },
                          _focus: { boxShadow: "none" },
                          _active: { bg: "none", boxShadow: "none" },
                        }}
                      >
                        {showPassword ? <ViewOffIcon /> : <ViewIcon />}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                  {formik.touched.password && formik.errors.password && (
                    <Text color="red.400" fontSize="sm">{formik.errors.password}</Text>
                  )}
                </FormControl>
              </VStack>

              {loginError && <Text color="red.500" mt={4}>{loginError}</Text>}

              <Button type="submit" sx={loginButtonStyle} mt={8} display="inline-flex">
                <Flex align="center" justify="center" gap="2px">
                  <Text>Login</Text>
                  <Image src={ButtonIcon} alt="Button icon" />
                </Flex>
              </Button>
            </form>

            <Link to="/create-account">
              <Button sx={loginButtonStyle} mt={4} display="inline-flex">
                <Flex align="center" justify="center" gap="2px">
                  <Text>Create Account</Text>
                  <Image src={ButtonIcon} alt="Button icon" />
                </Flex>
              </Button>
            </Link>

            <Flex align="center" justifyContent="center" mt={8} mb={14}>
              <Image src={line} alt="Line-svg" />
              <Text color="#B3B3B3" fontSize="14px" mx={4}>
                or, login with
              </Text>
              <Image src={line} alt="Line-svg" />
            </Flex>

            <Button sx={googleButtonStyle}>
              <Image src={GoogleIcon} alt="Google icon" />
              Sign in with Google
            </Button>
          </Box>
        </Box>

        <Box flex="1" display="flex" justifyContent="center" alignItems="center" height="100vh" width="100vw" overflow="hidden">
          <Image
            src={LoginPageGraphic}
            alt="login-page-graphic"
            objectFit="cover"
            height="100%"
            width="100%"
          />
        </Box>
      </Flex>
    </>
  );
};

export default Login;
