import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  styles: {
    global: {
      body: {
        fontFamily: "Mona Sans, sans-serif",
      },
    },
  },
  fonts: {
    body: "Mona Sans, sans-serif",
    heading: "Mona Sans, sans-serif",
  },
  components: {
    Button: {
      baseStyle: {
        _hover: {
          bg: "none",
          boxShadow: "none",
        },
        _focus: {
          boxShadow: "none",
        },
        _active: {
          bg: "none",
          boxShadow: "none",
        },
        borderRadius: "8px",
      },
      sizes: {
        md: {
          px: "16px",
          py: "8px",
        },
      },
      variants: {
        ghost: {
          _hover: {
            bg: "none",
          },
        },
        menuButton: {
          bg: "#ffffff",
          border: "none",
          color: "#021A04",
          _hover: {
            bg: "#F5F5F5", // Light grey background on hover
            color: "#021A04",
            boxShadow: "none",
          },
          _focus: {
            bg: "none",
            boxShadow: "none", // Remove focus effect
          },
          _active: {
            bg: "none",
            boxShadow: "none",
          },
        },
      },
    },
    Menu: {
      baseStyle: {
        button: {
          variant: "menuButton",
          bg: "#ffffff",
          border: "none",
          color: "#021A04",
          _hover: {
            bg: "#F5F5F5", // Light grey background on hover
            color: "#021A04",
            boxShadow: "none",
          },
          _focus: {
            bg: "none",
            boxShadow: "none", // Remove focus effect
          },
          _active: {
            bg: "none",
            boxShadow: "none",
          },
        },
        list: {
          borderRadius: "8px",
          boxShadow: "0px 0px 5.9px rgba(0, 0, 0, 0.14)", // Subtle shadow for dropdown
          _focus: {
            boxShadow: "none", // Remove blue focus border
          },
        },
        item: {
          _hover: {
            bg: "#F5F5F5", // Light grey hover effect
            color: "#021A04",
          },
          _focus: {
            bg: "none", // Remove blue focus background
            boxShadow: "none",
          },
          _active: {
            bg: "none", // Remove blue active background
            boxShadow: "none",
          },
          _selected: {
            bg: "none", // No background for selected items
            boxShadow: "none",
          },
        },
      },
    },
    Checkbox: {
      baseStyle: {
        control: {
          width: "18px",
          height: "18px",
          borderRadius: "4px",
          border: "1px solid #E7EAE8",
          boxShadow: "1px 1px 19.3px 0px rgba(0, 0, 0, 0.05)",
          fontSize: "14px",
          fontWeight: "400",
          _checked: {
            bg: "#4BFF5C",
            borderColor: "#4BFF5C",
            color: "#ffffff",
            _hover: {
              bg: "#4BFF5C",
              borderColor: "#4BFF5C",
            },
            _before: {
              content: '""',
              display: "block",
              width: "100%",
              height: "100%",
            },
          },
          _hover: {
            bg: "transparent",
            borderColor: "#4BFF5C",
          },
        },
        icon: {
          color: "#08250B",
        },
      },
    },
  },
});

export default theme;
