import React from 'react';
import { Flex, Text, Box } from '@chakra-ui/react';
import { keyframes } from '@emotion/react';

// Keyframes for the progress bar animation
const progressAnimation = keyframes`
  0% { width: 0; }
  100% { width: 100%; }
`;

const LoadingOverlay = () => (
  <Flex
    position="fixed"
    top="0"
    left="0"
    width="100vw"
    height="100vh"
    backgroundColor="#000000"
    justifyContent="center"
    alignItems="center"
    zIndex="1000"
    direction="column"
  >
    <Text color="#F1F1F1" fontSize="24px" fontWeight="500" mb="20px">
      Logging in...
    </Text>
    <Box
      width="20%" // Set the width of the progress bar container
      height="8px" // Height of the progress bar
      backgroundColor="#333" // Background color of the container
      borderRadius="4px"
      overflow="hidden"
      mt="20px"
    >
      <Box
        height="100%"
        backgroundColor="#4BFF5C" // Color of the progress bar itself
        borderRadius="4px"
        animation={`${progressAnimation} 5s linear forwards`} // 5-second animation
      />
    </Box>
  </Flex>
);

export default LoadingOverlay;
