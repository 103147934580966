import React, { useState } from 'react';
import { Box, Flex, useBreakpointValue } from '@chakra-ui/react';
import Sidebar from './Sidebar';
import Header from './Header';

const Layout = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const isDesktop = useBreakpointValue({ base: false, md: true });

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <Flex>
      {/* Sidebar */}
      <Box
        width={{ base: isSidebarOpen ? "252px" : "0", md: "252px" }}
        position="fixed"
        top="0"
        left="0"
        height="100vh"
        zIndex="999"
        transition="width 0.3s ease"
        overflow="hidden"
      >
        <Sidebar isOpen={isSidebarOpen} onToggle={toggleSidebar} />
      </Box>

      {/* Main content area */}
      <Box
        marginLeft={{ base: isSidebarOpen ? "252px" : "0", md: "252px" }}
        width={{ base: isSidebarOpen ? "calc(100vw - 252px)" : "100vw", md: "calc(100vw - 252px)" }}
        transition="margin-left 0.3s ease, width 0.3s ease"
      >
        {/* Header */}
        <Box
          position="fixed"
          top="0"
          left={{ base: isSidebarOpen ? "252px" : "0", md: "252px" }}
          right="0"
          zIndex="998"
          bg="white"
          p="4"
          boxShadow="sm"
        >
          <Header onSidebarToggle={toggleSidebar} isSidebarOpen={isSidebarOpen} />
        </Box>

        {/* Content */}
        <Box marginTop="100px" padding="24px">
          {children}
        </Box>
      </Box>
    </Flex>
  );
};

export default Layout;
