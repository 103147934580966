import React, { useEffect, useState } from "react";
import { Box, Spinner, Text, Flex, Heading, Button, SimpleGrid, VStack, HStack, Image, Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import MenuArrow from "../Images/Menu_Arrow.svg";
import AddIcon from "../Images/addIcon.svg";
import AddIconDark from "../Images/add-icon-dark.svg";
import MySubjectCard from "./MySubjectCard";
import SolveIcon from "../Images/Solve-icon.svg";
import DownloadIcon from "../Images/Download-icon.svg";
import axios from "axios";

const MySubjects = () => {
  const [subjects, setSubjects] = useState([]);
  const [pastPapers, setPastPapers] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showPastPapers, setShowPastPapers] = useState(false);

  const [showFilters, setShowFilters] = useState(false);
  const [boards, setBoards] = useState([]);
  const [qualifications, setQualifications] = useState([]);
  const [availableSubjects, setAvailableSubjects] = useState([]);
  const [selectedBoard, setSelectedBoard] = useState(null);
  const [selectedQualification, setSelectedQualification] = useState(null);
  const [selectedAvailableSubject, setSelectedAvailableSubject] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  const accessToken = localStorage.getItem('accessToken');
  const userGuid = localStorage.getItem('userGuid');

  useEffect(() => {
    // Fetch subjects linked to the user
    const fetchSubjects = async () => {
      try {
        setLoading(true);
        const response = await axios.get("https://stand-ai-api-v1.onrender.com/api/v1/user-subject-links/own/detail", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setSubjects(response.data);
        setError(null);
      } catch (err) {
        setError("Failed to fetch subjects.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchSubjects();
  }, [accessToken]);

  // Fetch past papers for the selected subject
  const fetchPastPapers = async (subjectGuid) => {
    try {
      setLoading(true);
      const response = await axios.get(`https://stand-ai-api-v1.onrender.com/api/v1/past-papers/by-subject-guid/${subjectGuid}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setPastPapers(response.data);
      setError(null);
    } catch (err) {
      setError(null);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleSubjectClick = (subject) => {
    setSelectedSubject(subject);
    fetchPastPapers(subject.subject_guid);
    setShowPastPapers(true);
  };

  // Fetch exam boards
  useEffect(() => {
    if (userGuid) {
      setLoading(true);
      axios
        .get(`https://stand-ai-api-v1.onrender.com/api/v1/exam-boards/`, {
          headers: { Authorization: `Bearer ${accessToken}` }
        })
        .then((response) => {
          setBoards(response.data);
          setError(null);
        })
        .catch((error) => {
          setError("There was an error fetching the exam boards.");
          console.error(error);
        })
        .finally(() => setLoading(false));
    } else {
      setError("User GUID is missing. Please log in again.");
    }
  }, [userGuid, accessToken]);

  // Fetch qualifications when a board is selected
  const handleSelectBoard = (board) => {
    setSelectedBoard(board);
    setLoading(true);
    axios.get(`https://stand-ai-api-v1.onrender.com/api/v1/exam-qualifications/by-exam-board-guid/${board.guid}`, {
      headers: { Authorization: `Bearer ${accessToken}` }
    })
    .then((response) => {
      setQualifications(response.data);
      setError(null);
    })
    .catch((error) => {
      setError("There was an error fetching the qualifications.");
      console.error(error);
    })
    .finally(() => setLoading(false));
  };

  // Fetch subjects when a qualification is selected
  const handleSelectQualification = (qualification) => {
    setSelectedQualification(qualification);
    setLoading(true);
    axios.get(`https://stand-ai-api-v1.onrender.com/api/v1/subjects/by-exam-qualification-guid/${qualification.guid}`, {
      headers: { Authorization: `Bearer ${accessToken}` }
    })
    .then((response) => {
      setAvailableSubjects(response.data);
      setError(null);
    })
    .catch((error) => {
      setError("There was an error fetching the subjects.");
      console.error(error);
    })
    .finally(() => setLoading(false));
  };

  const handleSelectAvailableSubject = (subject) => {
    setSelectedAvailableSubject(subject);
  };

  // Add subject for the user
  const handleAddSubject = () => {
    if (selectedAvailableSubject) {
      setLoading(true);
      axios.post(`https://stand-ai-api-v1.onrender.com/api/v1/user-subject-links/`, {
        user_guid: userGuid,
        subject_guid: selectedAvailableSubject.guid
      }, {
        headers: { Authorization: `Bearer ${accessToken}` }
      })
      .then((response) => {
        setSubjects([...subjects, selectedAvailableSubject]);
        setError(null);
      })
      .catch((error) => {
        setError("There was an error adding the subject.");
        console.error(error);
      })
      .finally(() => setLoading(false));
    }
  };

  const handleSubjectToggle = () => {
    setShowFilters(!showFilters);
  };

  return (
    <Flex>
      
      <Box  width="calc(100vw - 252px)">
       
        <Box >
        <Button
            onClick={handleSubjectToggle}
            borderRadius="14px"
            bg="#021A04"
            boxShadow="0px 0px 5.9px 0px rgba(0, 0, 0, 0.14)"
            padding="10px 34px"
            _hover={{
              bg: "#ffffff",
              color: "#021A04",
            }}
            onMouseEnter={() => setIsHovered(true)}  // Set hover state on enter
            onMouseLeave={() => setIsHovered(false)}  // Unset hover state on leave
          >
            <Flex flexDirection="row" justifyContent="left" alignItems="center" gap={2}>
              <Image src={isHovered ? AddIconDark : AddIcon} alt="Add Icon" /> {/* Toggle icon */}
              <Heading color={isHovered ? "#021A04" : "#F1F1F1"} fontSize="14px" fontWeight="400">
                Add Subjects
              </Heading>
            </Flex>
          </Button>

          {/* Subject Selection */}
          {showFilters && (
            <Flex mt={4} flexDirection="row" gap={8} alignItems="center" justifyContent="space-between" width="100%">
              <Menu>
                <MenuButton as={Button} variant="menuButton"  width="132px" height="28px" textAlign="center" fontSize="14px" fontWeight="400" background="white" border="2.333px solid #E7EAE8" borderRadius="9.333px" rightIcon={<img src={MenuArrow} alt="menu arrow" />}>
                  {selectedBoard ? selectedBoard.name : "Board Exam"}
                </MenuButton>
                <MenuList background="white" color="#021A04" textAlign="center" fontSize="12px" fontWeight="300">
                  {boards.map((board, index) => (
                    <MenuItem key={index} onClick={() => handleSelectBoard(board)}>
                      {board.code}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>

              <Menu>
                <MenuButton as={Button} variant="menuButton"  width="132px" height="28px" textAlign="center" fontSize="14px" fontWeight="400" background="white" border="2.333px solid #E7EAE8" borderRadius="9.333px" rightIcon={<img src={MenuArrow} alt="menu arrow" />}>
                  {selectedQualification ? selectedQualification.code : "Qualification"}
                </MenuButton>
                <MenuList background="white" color="#021A04" textAlign="center" fontSize="12px" fontWeight="300">
                  {qualifications.map((qualification, index) => (
                    <MenuItem key={index} onClick={() => handleSelectQualification(qualification)}>
                      {qualification.code}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>

              <Menu>
                <MenuButton as={Button} variant="menuButton"  width="132px" height="28px" textAlign="center" fontSize="14px" fontWeight="400" background="white" border="2.333px solid #E7EAE8" borderRadius="9.333px" rightIcon={<img src={MenuArrow} alt="menu arrow" />}>
                  {selectedAvailableSubject ? selectedAvailableSubject.name : "Subject"}
                </MenuButton>
                <MenuList background="white" color="#021A04" textAlign="center" fontSize="12px" fontWeight="300">
                  {availableSubjects.map((subject, index) => (
                    <MenuItem key={index} onClick={() => handleSelectAvailableSubject(subject)}>
                      {subject.name}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>

              <Button onClick={handleAddSubject}
                padding="8.864px 30.136px"
                borderRadius="12.409px"
                background="#021A04"
                color="#F1F1F1"
                fontSize="14px"
                fontWeight="400"
                boxShadow="0px 0px 5.23px 0px rgba(0, 0, 0, 0.14)"
                _hover={{
                  background: "#F1F1F1",
                  color: "#021A04",
                  boxShadow: "0px 0px 5.9px 0px rgba(0, 0, 0, 0.14)"
                }}
              >
                Add Selection
              </Button>
            </Flex>
          )}

          {/* Displaying subjects linked to the user */}
          <Box borderTop="2px solid #E7EAE8" mt={4}>
            {loading && <Spinner size="xl" color="#4BFF5C" />}
            {error && <Text color="red.500">{error}</Text>}
            {!loading && subjects.length > 0 ? (
              <SimpleGrid columns={4} spacing={4} marginTop="24px">
                {subjects.map((subject, index) => (
                  <MySubjectCard
                    key={index}
                    subjectName={subject.subject_name}
                    examBoardCode={subject.exam_board_code}
                    qualificationCode={subject.exam_qualification_code}
                    onClick={() => handleSubjectClick(subject)} // Pass the subject GUID when clicked
                  />
                ))}
              </SimpleGrid>
            ) : (
              <Text marginTop="24px" color="#021A04" fontSize="20px" fontWeight="200">
                No subjects found.
              </Text>
            )}
          </Box>

          {/* Show past papers if a subject is selected */}
          {showPastPapers && selectedSubject && (
            <>
              <Heading as="h3" color="#021A04" fontSize="18px" fontWeight="400" mt={8} mb={8}>
                Past Year Papers for {selectedSubject.subject_name}
              </Heading>
              <Box>
                {pastPapers.length > 0 ? (
                  <Box width="354px" height="auto" bg="#FFF" borderRadius="14px" boxShadow="0px 0px 9.6px 0px rgba(0, 0, 0, 0.1111)" p="16px" paddingBottom="54px">
                    <VStack spacing={8} align="left">
                      {pastPapers.map((paper, index) => (
                        <Box key={index}>
                          <Button variant="unstyled" color="#021A04" fontSize="14px" fontWeight="400">
                            {`Paper ${paper.name.split(' ')[1]}${paper.code.trim().slice(-1)} ${paper.name.split(' ').slice(2).join(' ')}`}
                          </Button>

                          <HStack spacing={4}>
                            <Button variant="link" color="#535953" fontSize="14px" fontWeight="300">
                              Solve <Image src={SolveIcon} alt="Solve Icon" ml="4px" />
                            </Button>

                            <Button variant="link" color="#535953" fontSize="14px" fontWeight="300">
                              Download <Image src={DownloadIcon} alt="Download Icon" ml="4px" />
                            </Button>
                          </HStack>
                        </Box>
                      ))}
                    </VStack>
                  </Box>
                ) : (
                  <Text color="#021A04" fontSize="16px">
                    Past year papers for this subject will be added soon  
                  </Text>
                )}
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Flex>
  );
};

export default MySubjects;
